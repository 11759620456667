<template>
    <ul
        class="container breadcrumbs flex flex-wrap mb-10 lg:mt-10 text-sm"
        :class="[className]"
    >
        <li class="mr-2">
            Du er her
            <span class="px-2">&rarr;</span>
        </li>
        <li v-for="(item, index) in items" :key="$uuid.v4(index)" class="mr-2">

            <a v-if="item.url" :href="item.url" class="hover:underline">
                <span v-if="index === 0">Forside</span>
                <span v-else>{{ item.text }}</span>
            </a>
            <span v-else>
                {{ item.text }}
            </span>
            <span v-if="index !== items.length - 1" class="px-2">&rarr;</span>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        breadcrumbs: Array,
        skipLast: {
            type: Boolean,
            'default': false
        },
        className: String
    },
    data() {
        return {
            items: this.skipLast ? this.breadcrumbs.slice(0, -1) : this.breadcrumbs
        };
    }
};
</script>
