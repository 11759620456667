<template>
    <div class="text-center" :class="className">
        <font-awesome-icon :class="[`fa-spin ${iconsize}`]" :icon="['fas', 'spinner']"/>
    </div>
</template>

<script>
export default {
    props: {
        className: String,
        size: {
            type: String,
            'default': null
        },
    },
    data() {
        const iconsize = this.size ? `fa-${this.size}` : '';
        return {
            iconsize
        };
    }
};
</script>
