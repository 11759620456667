var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "flex flex-col space-y-4 w-full lg:max-w-5xl lg:mx-auto",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.createThread()
        }
      }
    },
    [
      _vm.loading
        ? [_c("LoadingSpinner", { attrs: { size: "2x" } })]
        : _vm._e(),
      _vm._v(" "),
      !_vm.error && !_vm.loading
        ? [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.threadTitle,
                  expression: "threadTitle"
                }
              ],
              staticClass: "border p-3 rounded bg-white",
              attrs: { placeholder: "Tittel" },
              domProps: { value: _vm.threadTitle },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.threadTitle = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.threadText,
                  expression: "threadText"
                }
              ],
              staticClass: "border p-3 rounded bg-white h-48",
              attrs: { placeholder: "Tekst" },
              domProps: { value: _vm.threadText },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.threadText = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c(
                "button",
                { staticClass: "btn btn-blue", attrs: { type: "submit" } },
                [_vm._v("Opprett innlegg")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-red",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("Avbryt")]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? [
            _c(
              "div",
              {
                staticClass:
                  "text-center bg-red-100 border border-red-200 text-red-600 p-4"
              },
              [
                _vm._v(
                  "\n            Beklager, det oppso en feil! Prøv igjen. Hvis problemet fortsetter vennligst ta kontakt med oss.\n        "
                )
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }