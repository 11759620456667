var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._v("\n    Del denne siden:"),
    _c("br"),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "hover:text-blue-500 text-3xl",
        staticStyle: { color: "#4267B2" },
        attrs: {
          href: _vm.shareFacebookUrl,
          target: "_blank",
          title: "Del på Facebook"
        }
      },
      [
        _c("font-awesome-icon", {
          directives: [
            {
              name: "tippy",
              rawName: "v-tippy",
              value: { placement: "bottom" },
              expression: "{ placement : 'bottom' }"
            }
          ],
          attrs: {
            icon: ["fab", "facebook-square"],
            content: "Del på Facebook"
          }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [
          _vm._v(
            "\n            Del denne siden på Facebook (åpnes i nytt vindu)\n        "
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "hover:text-blue-500 text-3xl",
        staticStyle: { color: "#1DA1F2" },
        attrs: {
          href: _vm.shareTwitterUrl,
          target: "_blank",
          title: "Del på Twitter"
        }
      },
      [
        _c("font-awesome-icon", {
          directives: [
            {
              name: "tippy",
              rawName: "v-tippy",
              value: { placement: "bottom" },
              expression: "{ placement : 'bottom' }"
            }
          ],
          attrs: { icon: ["fab", "twitter-square"], content: "Del på Twitter" }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [
          _vm._v(
            "\n            Del denne siden på Twitter (åpnes i nytt vindu)\n        "
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "a",
      {
        staticClass: "text-grey-400 hover:text-blue-500 text-3xl",
        attrs: { href: "#", title: "Skriv ut denne siden" },
        on: {
          click: function($event) {
            $event.preventDefault()
            return _vm.print()
          }
        }
      },
      [
        _c("font-awesome-icon", {
          directives: [
            {
              name: "tippy",
              rawName: "v-tippy",
              value: { placement: "bottom" },
              expression: "{ placement : 'bottom' }"
            }
          ],
          attrs: { icon: "print", content: "Skriv ut denne siden" }
        }),
        _vm._v(" "),
        _c("span", { staticClass: "sr-only" }, [
          _vm._v("\n            Skriv ut denne siden\n        ")
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }