var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "bg-blue-400 text-white border-b-8 border-yellow-400 py-4 mb-6"
      },
      [
        _c("header", { staticClass: "container relative" }, [
          _c("div", { staticClass: "flex" }, [
            _c("div", { staticClass: "w-3/6" }, [
              _c("a", { attrs: { href: "/", title: "Kommunetorget.no" } }, [
                _c("img", { attrs: { src: _vm.logo } })
              ])
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "w-2/6 hidden lg:flex justify-end items-center" },
              [
                _c(
                  "form",
                  { staticClass: "w-3/4", attrs: { action: "/sok" } },
                  [
                    _c("fieldset", [
                      _c("legend", { staticClass: "sr-only" }, [
                        _vm._v(
                          "\n                                Søkeskjema\n                            "
                        )
                      ]),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "sr-only",
                          attrs: { for: "globalsearch" }
                        },
                        [_vm._v("Søkefelt")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "relative" }, [
                        _c("input", {
                          staticClass: "bg-blue-500 rounded p-3 pl-10 w-full",
                          attrs: {
                            type: "text",
                            id: "globalsearch",
                            name: "ord",
                            placeholder: "Søk i alt innhold"
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "absolute left-3 top-3 text-blue-200",
                            attrs: { type: "submit" }
                          },
                          [
                            _c("span", { staticClass: "sr-only" }, [
                              _vm._v("Søk")
                            ]),
                            _vm._v(" "),
                            _c("font-awesome-icon", {
                              attrs: { icon: "search" }
                            })
                          ],
                          1
                        )
                      ])
                    ])
                  ]
                )
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "w-3/6 lg:w-1/6 flex justify-end items-center relative"
              },
              [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-blue mr-3 inline-block lg:hidden",
                    attrs: { href: "/sok/", title: "Gå til søkesiden" }
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Gå til søkesiden")
                    ]),
                    _vm._v(" "),
                    _c("font-awesome-icon", { attrs: { icon: "search" } })
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-blue",
                    attrs: { title: "Trykk for å åpne/lukke menyen" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        _vm.showMenu = !_vm.showMenu
                      }
                    }
                  },
                  [
                    !_vm.showMenu
                      ? _c("font-awesome-icon", {
                          staticClass: "mr-0 lg:mr-1",
                          attrs: { icon: "bars" }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showMenu
                      ? _c("font-awesome-icon", {
                          staticClass: "mr-0 lg:mr-1",
                          attrs: { icon: "times" }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("span", { staticClass: "hidden lg:inline-block" }, [
                      _vm._v("Alt innhold")
                    ])
                  ],
                  1
                )
              ]
            )
          ]),
          _vm._v(" "),
          _vm.showMenu
            ? _c(
                "nav",
                {
                  staticClass:
                    "-top-4 lg:top-auto absolute -left-4 -right-4 z-50 lg:right-0 lg:left-auto lg:bg-white bg-purple-100 lg:rounded p-6 px-8 lg:p-4 text-gray-900 shadow-sm lg:shadow-md w-auto lg:w-full lg:max-w-md text-lg lg:text-sm"
                },
                [
                  _c("div", { staticClass: "triangle hidden lg:block" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "block lg:hidden text-right mt-1" },
                    [
                      _c(
                        "button",
                        {
                          staticClass:
                            "text-base inline-block py-2 px-3 rounded border border-blue-600 text-blue-600 bg-purple-100 hover:bg-white",
                          on: {
                            click: function($event) {
                              $event.preventDefault()
                              _vm.showMenu = !_vm.showMenu
                            }
                          }
                        },
                        [
                          _c("span", { staticClass: "sr-only" }, [
                            _vm._v("Åpne/lukk menyen")
                          ]),
                          _vm._v(" "),
                          _c("font-awesome-icon", {
                            staticClass: "lg:mr-1",
                            attrs: { icon: "times" }
                          })
                        ],
                        1
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c("Menu", { attrs: { menu: _vm.model.menu } })
                ],
                1
              )
            : _vm._e()
        ])
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }