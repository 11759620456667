<template>
    <div class="relative w-full md:w-1/3 mb-4 hover:shadow-lg">
        <div class="w-full relative imageplaceholder">
            <img
                :src="imageUrl"
                :alt="article.heading"
                class="absolute top-0 left-0"
            />
        </div>
        <div class="p-4">
            <h3 v-text="article.heading" class="font-bold text-xl text-textgray mb-2"></h3>
            <p v-html="article.intro"></p>
        </div>
        <a
            :href="article.url"
            :title="article.heading"
            class="absolute w-full h-full top-0 left-0"
        >
            <span class="sr-only">Les mer</span>
        </a>
    </div>
</template>

<script>

export default {
    props: ['article'],
    computed: {
        imageUrl() {
            return this.article.image + '?width=721&height=480&' + this.article.imageResizerParams;
        }
    }
};
</script>


<style scoped>
    .imageplaceholder {
        padding-top: 66.66%;
    }
</style>
