<template>
    <li class="highlight p-4 -mx-4 my-4 bg-blue-100">
        <a
            :href="item.url"
            :title="item.text"
            class="text-lg lg:text-base text-purple-500 font-bold no-underline hover:underline"
        >
            {{ item.text }}
        </a>
    </li>
</template>

<script>
export default {
    name: 'Item',
    props: ['item']
};
</script>

