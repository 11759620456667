<template>
    <ul class="py-4 my-4">
        <li v-for="item in items" :key="item.id" class="mb-6">
            <a
                :href="item.url"
                :title="item.name"
                class="text-blue-500 hover:text-red-500 underline hover:no-underline mb-1"
            >
                {{ item.name }}
            </a>
            <div class="text-sm text-gray-600 mb-1">
                <span
                    v-if="item.startPublish"
                    v-text="dateFormated(item.startPublish)"
                ></span>
                <span
                    v-if="item.author"
                    v-html="` &mdash; ${item.author}`"
                ></span>
            </div>
            <div
                v-if="item.introAsText"
                v-text="item.introAsText"
                class="text-sm"
            ></div>
        </li>
    </ul>
</template>
<script>
export default ({
    props: ['items'],
    methods: {
        dateFormated(startPublish) {
            const d = new Date(startPublish);
            const day = ('0' + d.getDate()).slice(-2);
            const month = ('0' + (d.getMonth() + 1)).slice(-2);
            const year = d.getFullYear();
            return day + '.' + month + '.' + year;
        },
    }
});
</script>
