<template>
    <li class="mb-4">
        <template v-if="item.children.length === 0">
            <a
                :href="item.url"
                :title="item.text"
                class="text-purple-600 fomt-bold no-underline hover:underline"
            >
                <span class="text-sm text-black mr-1">
                    <font-awesome-icon icon="arrow-right" />
                </span>
                {{ item.text }}
            </a>
        </template>

        <template v-if="item.children.length > 0">
            <a
                href="#"
                :title="item.text"
                @click.prevent="showChildren = !showChildren"
                class="text-lg lg:text-base text-gray-800 font-bold no-underline hover:underline"
            >
                <span class="text-sm text-black mr-1">
                    <font-awesome-icon v-if="!showChildren" icon="chevron-down" />
                    <font-awesome-icon v-if="showChildren" icon="chevron-up" />
                </span>
                {{ item.text }}
            </a>

            <ul v-show="showChildren" class="pl-4 list-outside mt-4">
                <template v-for="(item, index) in item.children">
                    <Item :item="item" :key="item.url + index" />
                </template>
            </ul>
        </template>
    </li>
</template>

<script>
export default {
    name: 'Item',
    props: ['item'],
    data() {
        return {
            showChildren: false
        };
    }
};
</script>

