var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "text-center", class: _vm.className },
    [
      _c("font-awesome-icon", {
        class: ["fa-spin " + _vm.iconsize],
        attrs: { icon: ["fas", "spinner"] }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }