<template>
    <div>
        <Header :model="model"/>
        <slot :model="model"></slot>
        <Footer :model="model" />
    </div>
</template>

<script>
import Header from '../organisms/Header/header.vue';
import Footer from '../organisms/Footer/footer.vue';

export default {
    components: {
        Header,
        Footer,
    },
    props: ['model']
};
</script>
