var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultTemplate",
    { attrs: { model: _vm.model } },
    [
      _c("Breadcrumbs", { attrs: { breadcrumbs: _vm.model.breadcrumbs } }),
      _vm._v(" "),
      _c("div", { staticClass: "container lg:flex mb-10" }, [
        _c("div", { staticClass: "w-full" }, [
          _vm.model.name
            ? _c("h1", {
                staticClass:
                  "font-bold text-5xl text-textgray lg:text-textgray-light mb-10",
                domProps: { textContent: _vm._s(_vm.model.name) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.model.mainBody
            ? _c("div", { domProps: { innerHTML: _vm._s(_vm.model.mainBody) } })
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c("div", [_c("LoadingSpinner", { attrs: { size: "2x" } })], 1)
            : _c("div", [
                _c("div", { staticClass: "flex flex-row justify-end" }, [
                  _vm.model.allowThreads
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-blue",
                          on: {
                            click: function($event) {
                              return _vm.handleOpenNewThreadDialog()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: _vm.openNewThreadDialog ? "times" : "plus"
                            }
                          }),
                          _vm._v(
                            "\n                        Legg til innlegg\n                    "
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.allowForums
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-blue",
                          on: {
                            click: function($event) {
                              return _vm.handleOpenNewForumDialog()
                            }
                          }
                        },
                        [
                          _c("font-awesome-icon", {
                            attrs: {
                              icon: _vm.openNewForumDialog ? "times" : "plus"
                            }
                          }),
                          _vm._v(
                            "\n                        Legg til mappe\n                    "
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]),
                _vm._v(" "),
                _vm.openNewForumDialog
                  ? _c(
                      "div",
                      { staticClass: "border bg-blue-100 p-10 block mt-4" },
                      [
                        _c("NewForumDialog", {
                          attrs: { model: _vm.model },
                          on: { clicked: _vm.handleOpenNewForumDialog }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.openNewThreadDialog
                  ? _c(
                      "div",
                      { staticClass: "border bg-blue-100 p-10 block mt-4" },
                      [
                        _c("NewThreadDialog", {
                          attrs: { model: _vm.model },
                          on: { clicked: _vm.handleOpenNewThreadDialog }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-4 divide-y" },
                  [
                    _vm._l(_vm.forums.children, function(forum, index) {
                      return [
                        index === 0 && forum.visible
                          ? _c(
                              "div",
                              {
                                key: forum.id,
                                staticClass:
                                  "flex divide-x bg-blue-100 text-blue-600"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-full md:w-1/2 lg:w-1/4 p-2 font-bold text-lg"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Forum\n                            "
                                    )
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "hidden md:block w-1/2 lg:w-1/4 p-2 font-bold text-lg"
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Siste oppdatert innlegg\n                            "
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        forum.visible
                          ? _c(
                              "div",
                              {
                                key: forum.id,
                                staticClass: "md:flex py-2 md:divide-x",
                                class: index % 2 ? "bg-gray-100" : "bg-white"
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "w-full md:w-1/2 lg:w-1/4 px-2"
                                  },
                                  [
                                    _c("font-awesome-icon", {
                                      staticClass: "mr-2",
                                      attrs: { icon: "comments" }
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass:
                                          "underline hover:text-blue-500",
                                        attrs: { href: forum.url }
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(" " + _vm._s(forum.name))
                                        ])
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "hidden md:block w-1/2 lg:w-3/4 px-2"
                                  },
                                  [
                                    forum.latestUpdatedThread
                                      ? [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                new Date(
                                                  forum.latestUpdatedThread.startPublish
                                                ).toLocaleDateString(
                                                  "nb-NO",
                                                  _vm.dateOptions
                                                )
                                              ) +
                                              "\n                                    -\n                                    "
                                          ),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "underline hover:text-blue-500",
                                              attrs: {
                                                href:
                                                  forum.latestUpdatedThread.url
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    forum.latestUpdatedThread
                                                      .name
                                                  ) +
                                                  "\n                                    "
                                              )
                                            ]
                                          )
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    })
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-4" },
                  [
                    _vm._l(_vm.forums.children, function(forum) {
                      return [
                        _vm._l(forum, function(thread, index) {
                          return [
                            thread &&
                            thread.visible && thread.pageType !== "ForumPage"
                              ? _c(
                                  "div",
                                  {
                                    key: thread.id + index,
                                    staticClass: "divide-y"
                                  },
                                  [
                                    index === 0
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "md:flex text-blue-600 bg-blue-100 font-bold text-lg divide-x"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "w-full md:w-2/4 p-2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Innlegg\n                                    "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "hidden md:block w-1/4 p-2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Sist oppdatert\n                                    "
                                                )
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "hidden md:block w-1/4 text-right p-2"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        Svar\n                                    "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("ThreadListItem", {
                                      class:
                                        index % 2 ? "bg-gray-100" : "bg-white",
                                      attrs: { thread: thread }
                                    })
                                  ],
                                  1
                                )
                              : _vm._e()
                          ]
                        })
                      ]
                    })
                  ],
                  2
                )
              ])
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }