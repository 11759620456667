<template>
    <div class="FormContainerBlock">
        <div :id="'formContainer_' + model.contentLink.id"></div>
    </div>
</template>

<script>
import EpiProperty from '@/Scripts/components/EpiProperty.vue';
import ContentArea from '@/Scripts/components/ContentArea.vue';
import formRenderingService from '../../../../../Scripts/EPiServer.ContentApi.Forms/FormRenderingService';

export default
{
    props: ['model'],
    methods:
    {
        renderForm: function () {
            var element = document.getElementById('formContainer_' + this.model.contentLink.id);
            formRenderingService.render(this.model.formModel, element);
        }
    },
    components:
    {
        EpiProperty,
        ContentArea
    },
    mounted() {
        this.renderForm();
    },
    updated: function () {
        this.renderForm();
    }
};
</script>
