<template>
    <DefaultTemplate :model="model">
        <Breadcrumbs :breadcrumbs="model.breadcrumbs" />
        <div class="container lg:flex mb-10">
            <div
                class="w-full"
                :class="rightcol ? 'lg:w-4/6' : 'lg:max-w-6xl mx-auto'"
            >
                <article-body :model="model">
                    <section v-if="model.secondaryListItems">
                        <secondary-list :items="model.secondaryListItems"></secondary-list>
                    </section>

                    <section v-if="model.questions">
                        <question-list :items="model.questions"></question-list>
                    </section>

                    <section v-if="model.topics && model.showBoxes">
                        <ExploreThemes :themes="model.topics" :headerline="false" />
                    </section>

                    <NavChildren v-if="model.children && model.contentType[1] === 'KommunetorgetTopicPage' && !model.showBoxes" :childrens="model.children"/>

                    <section v-if="model.video" v-html="model.video" class="article--video"></section>
                </article-body>

                <div class="my-4 py-4 border-t border-gray-300 text-xs">
                    <Sharing :title="model.heading" />
                </div>
            </div>

            <div v-if="rightcol || inEditMode" class="w-full mt-6 lg:mt-0 lg:w-2/6">
                <div class="lg:p-6 lg:bg-white lg:bg-opacity-50">
                    <img v-epi-edit="'RightImage'" v-if="model.rightImage" :src="model.rightImage" class="mb-4" />
                    <img v-if="model.rightImage2" :src="model.rightImage2" class="mb-4" />
                    <p v-if="model.rightImageText" class="mb-4">
                        {{model.rightImageText}}
                    </p>
                    <div v-if="model.rightArea" v-html="model.rightArea"></div>

                    <template v-if="(model.relatedDocuments && model.relatedDocuments.length > 0) || inEditMode">
                        <h3 class="font-bold text-lg mb-1">Relaterte dokumenter</h3>
                        <ul v-epi-edit="'RelatedDocuments'" class="mb-4">
                            <li v-for="doc in model.relatedDocuments" :key="doc.url">
                                &rarr;&nbsp;
                                <a
                                    :href="doc.url"
                                    :title="doc.text"
                                    :target="doc.target"
                                    class="underline hover:no-underline text-blue-400 hover:text-red-400"
                                >
                                    {{ doc.text }}
                                </a>
                            </li>
                        </ul>
                    </template>
                </div>
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';
import ArticleBody from '@/Scripts/components/organisms/Article/Article.vue';
import SecondaryList from '@/Scripts/components/organisms/SecondaryList/secondaryList.vue';
import QuestionList from '@/Scripts/components/organisms/QuestionList/questionList.vue';
import NavChildren from '@/Scripts/components/organisms/NavChildren/NavChildren.vue';
import Sharing from '@/Scripts/components/atoms/Sharing/Sharing.vue';
import ExploreThemes from '@/Scripts/components/blocks/ExploreThemes/ExploreThemes.vue';
import { mapGetters } from 'vuex';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
        ArticleBody,
        SecondaryList,
        Sharing,
        QuestionList,
        NavChildren,
        ExploreThemes
    },
    computed: {
        ...mapGetters({
            inEditMode: 'getInEditMode'
        }),
        rightcol() {
            if (this.model.relatedDocuments && this.model.relatedDocuments.length > 0) {
                return true;
            }

            if (this.model.rightImage) {
                return true;
            }

            if (this.model.rightImage2) {
                return true;
            }

            if (this.model.rightImageText) {
                return true;
            }

            if (this.model.rightArea) {
                return true;
            }

            return false;
        },
    }
};
</script>
