var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "footer",
    {
      staticClass:
        "bg-blue-400 text-white border-t-8 border-yellow-400 py-10 footer"
    },
    [
      _c(
        "div",
        {
          staticClass:
            "container lg:flex divide-y divide-purple-100 lg:divide-y-0"
        },
        [
          _c("div", { staticClass: "w-full sm:flex lg:block py-6 lg:py-0" }, [
            _c("div", { staticClass: "w-full" }, [
              _c(
                "a",
                {
                  attrs: {
                    href: "https://www.kommunetorget.no/",
                    title: "Til Kommunetorget.no",
                    target: "_blank"
                  }
                },
                [_c("img", { attrs: { src: _vm.logo_outlined } })]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "my-6" }, [
                _c(
                  "a",
                  {
                    staticClass: "font-bold",
                    attrs: {
                      href: _vm.model.footer.email.href,
                      title: "Send epost"
                    }
                  },
                  [_vm._v(_vm._s(_vm.model.footer.email.text))]
                )
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "w-full" }, [
              _c("p", { staticClass: "mb-4" }, [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-blue",
                    attrs: {
                      href: _vm.model.footer.loginForum.href,
                      title: _vm.model.footer.loginForum.text
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "comments" }
                    }),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.model.footer.loginForum.text) +
                        "\n                    "
                    )
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-blue",
                    attrs: {
                      href: _vm.model.footer.loginEditors.href,
                      title: _vm.model.footer.loginEditors.text
                    }
                  },
                  [
                    _c("font-awesome-icon", {
                      staticClass: "mr-1",
                      attrs: { icon: "sign-in-alt" }
                    }),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.model.footer.loginEditors.text) +
                        "\n                    "
                    )
                  ],
                  1
                )
              ])
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full py-6 lg:py-0" }, [
            _c("div", {
              directives: [
                {
                  name: "epi-edit",
                  rawName: "v-epi-edit",
                  value: "ColumnTwoText",
                  expression: "'ColumnTwoText'"
                }
              ],
              staticClass: "o-textarea",
              domProps: { innerHTML: _vm._s(_vm.model.footer.columnTwoText) }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "w-full py-6 lg:py-0" }, [
            _c(
              "a",
              {
                staticClass: "mb-4",
                attrs: { href: "http://www.korusnord.no", title: "KorusNord" }
              },
              [
                _c("img", {
                  staticClass: "w-32",
                  attrs: { src: _vm.logo_korusnord }
                })
              ]
            ),
            _vm._v(" "),
            _c("br")
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }