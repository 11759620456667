<template>
    <div>
        <p
            class="py-1 px-2 bg-blue-100 text-blue-500 text-xs inline-block mb-1"
        >
            {{item.site}}
        </p>
        <h3 class="md:flex md:justify-between text-lg mb-2 md:items-start">
            <a :href="item.url"
                :title="item.title"
                class="text-blue-500 underline hover:text-red-500">
                {{ item.title }}
            </a>
        </h3>

        <div>
            <span v-if="item.publishDate" v-text="dateFormated" class="font-semibold"></span>
            <span v-html="item.extract"></span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['item'],
    computed: {
        dateFormated() {
            const d = new Date(this.item.publishDate);
            const day = ('0' + d.getDate()).slice(-2);
            const month = ('0' + (d.getMonth() + 1)).slice(-2);
            const year = d.getFullYear();
            return day + '.' + month + '.' + year;
        }
    }
};
</script>
