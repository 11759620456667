<template>
    <section class="mb-20">
        <div v-if="headerline">
            <HeaderLine :text="'Utforsk tema'" />
        </div>

        <div class="flex flex-wrap -mx-4">
            <div v-for="(theme, index) in themes" :key="index" class="w-full md:w-1/2 lg:w-1/3 p-4">
                <div class="relative border rounded-b-lg shadow hover:shadow-lg h-full group">
                    <div
                        :class="theme.themeColor ? `theme-border-${theme.themeColor}` : ''"
                        class="border-t-8 p-6 group-hover:border-black"
                    >
                        <div class="flex items-center mb-6">
                            <div
                                v-if="theme.image"
                                class="w-16 mr-1"
                            >
                                <img
                                    :src="theme.image"
                                    :alt="theme.heading"
                                />
                            </div>
                            <div :class="theme.themeColor ? `theme-text-${theme.themeColor}` : ''">
                                <span class="text-xs">Utforsk tema</span>
                                <h3
                                    v-html="theme.heading"
                                    class="font-bold text-xl"
                                ></h3>
                            </div>
                        </div>

                        <p
                            v-if="theme.intro"
                            v-html="theme.intro"
                            class="text-base leading-6"
                        ></p>
                    </div>
                    <a
                        :href="theme.url"
                        :title="theme.heading"
                        class="absolute w-full h-full top-0 left-0 cursor-pointer"
                    >
                        <span class="sr-only">Les mer</span>
                    </a>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import HeaderLine from '@/Scripts/components/atoms/HeaderLine/HeaderLine.vue';
export default {
    props: ['themes', 'headerline'],
    components: {
        HeaderLine
    }
};
</script>
