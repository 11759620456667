<template>
    <nav class="lg:flex lg:flex-wrap my-6">
        <div v-for="(children, index) in childrens" :key="index" class="w-full lg:w-1/2 mb-6">
            <h3 class="font-bold mb-1">
                {{ children.text }}
            </h3>
            <ul>
                <li
                    v-for="(child, index) in children.children"
                    :key="index"
                    class="mb-1"
                >
                    &rarr;
                    <a
                        :href="child.url"
                        :title="child.text"
                        class="text-blue-400 hover:text-red-400 no-underline hover:underline"
                    >
                        {{ child.text }}
                    </a>
                </li>
            </ul>
        </div>
    </nav>
</template>
<script>
export default {
    props: ['childrens']
};
</script>
