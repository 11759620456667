var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    [
      _vm._l(_vm.menu, function(item, index) {
        return [
          item.highlighted
            ? _c("ItemHighlighted", {
                key: item.url + index,
                attrs: { item: item }
              })
            : _c("Item", { key: item.url + index, attrs: { item: item } })
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }