var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "md:flex py-2 divide-x" }, [
    _c(
      "div",
      { staticClass: "w-full md:w-2/4 px-2" },
      [
        _c("font-awesome-icon", {
          staticClass: "mr-2",
          attrs: { icon: "comment-dots" }
        }),
        _vm._v(" "),
        _c(
          "a",
          {
            staticClass: "underline hover:text-blue-500",
            attrs: { href: _vm.thread.url, title: _vm.thread.name }
          },
          [_vm._v("\n            " + _vm._s(_vm.thread.name) + "\n        ")]
        ),
        _c("br"),
        _vm._v(" "),
        _c("span", { staticClass: "text-xs" }, [
          _vm._v(
            "\n            Av: " + _vm._s(_vm.thread.createdBy) + "\n        "
          )
        ])
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "hidden md:block w-1/4 px-2" }, [
      _vm._v("\n        " + _vm._s(_vm.dateFormated) + "\n    ")
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "hidden md:block w-1/4 px-2 text-right" }, [
      _vm._v("\n        " + _vm._s(_vm.thread.numberOfReplies) + "\n    ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }