<template>
    <ul class="my-6">
        <li v-for="item in items" :key="item.id" class="mb-2">
            &rarr;
            <a
                :href="item.url"
                :title="item.name"
                class="text-blue-500 hover:text-red-500 underline hover:no-underline"
            >
                {{ item.name }}
            </a>
        </li>
    </ul>
</template>
<script>
export default ({
    props: ['items']
});
</script>
