<template>
    <footer class="bg-blue-400 text-white border-t-8 border-yellow-400 py-10 footer">
        <div class="container lg:flex divide-y divide-purple-100 lg:divide-y-0">
            <div class="w-full sm:flex lg:block py-6 lg:py-0">
                <div class="w-full">
                    <a href="https://www.kommunetorget.no/" title="Til Kommunetorget.no" target="_blank">
                        <img :src="logo_outlined" />
                    </a>
                    <p class="my-6">
                        <a :href="model.footer.email.href" title="Send epost" class="font-bold">{{model.footer.email.text}}</a>
                    </p>
                </div>
                <div class="w-full">
                    <p class="mb-4">
                        <a :href="model.footer.loginForum.href" :title="model.footer.loginForum.text " class="btn btn-blue">
                            <font-awesome-icon icon="comments" class="mr-1" />
                            {{ model.footer.loginForum.text }}
                        </a>
                    </p>
                    <p>
                        <a :href="model.footer.loginEditors.href" :title="model.footer.loginEditors.text" class="btn btn-blue">
                            <font-awesome-icon icon="sign-in-alt" class="mr-1" />
                            {{ model.footer.loginEditors.text }}
                        </a>
                    </p>
                </div>
            </div>
            <div class="w-full py-6 lg:py-0">
                <div v-html="model.footer.columnTwoText" v-epi-edit="'ColumnTwoText'" class="o-textarea"></div>
            </div>
            <div class="w-full py-6 lg:py-0">
                <a href="http://www.korusnord.no" title="KorusNord" class="mb-4">
                    <img :src="logo_korusnord" class="w-32" />
                </a>
                <br />
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    props: ['model'],
    data() {
        return {
            logo_outlined: '/Assets/Images/Kommunetorget/logo_outlined.png',
            logo_korusnord: '/Assets/Images/Kommunetorget/KORUS-Nord_Logo_Hvit.png',
            logo_helsedir: '/Assets/Images/Kommunetorget/helsedir-logo.png'
        };
    },
    methods: {
        log(obj) {
            console.log(obj);
        }
    }
};
</script>
