<!--
    Renders a button to edit a property in OPE.
-->

<template>
    <button class="bg-blue-400 hover:bg-grey-700 text-white py-1 px-2 mb-2 mr-2"
            v-if="isEditable"
            v-epi-edit="propertyName"
            type="button">
        <span>
            <font-awesome-icon icon="edit" /> {{buttontext}}
        </span>
    </button>
</template>

<script>
import { mapState } from 'vuex';

export default {
    props: ['propertyName', 'displayText'],
    computed: mapState({
        isEditable: state => state.epiContext.isEditable
    }),
    data() {
        return {
            buttontext: this.displayText ? this.displayText : this.propertyName
        };
    },
    methods: {
        log(obj) {
            console.log(obj);
        },
    }
};
</script>
