var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "lg:flex lg:flex-wrap my-6" },
    _vm._l(_vm.childrens, function(children, index) {
      return _c("div", { key: index, staticClass: "w-full lg:w-1/2 mb-6" }, [
        _c("h3", { staticClass: "font-bold mb-1" }, [
          _vm._v("\n            " + _vm._s(children.text) + "\n        ")
        ]),
        _vm._v(" "),
        _c(
          "ul",
          _vm._l(children.children, function(child, index) {
            return _c("li", { key: index, staticClass: "mb-1" }, [
              _vm._v("\n                →\n                "),
              _c(
                "a",
                {
                  staticClass:
                    "text-blue-400 hover:text-red-400 no-underline hover:underline",
                  attrs: { href: child.url, title: child.text }
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(child.text) +
                      "\n                "
                  )
                ]
              )
            ])
          }),
          0
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }