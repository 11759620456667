var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "pagination flex flex-row justify-center items-center mt-4"
    },
    [
      _c(
        "button",
        {
          staticClass:
            "mr-4 p-3 disabled:opacity-30 disabled:cursor-not-allowed",
          attrs: { disabled: _vm.currentPage === 1, title: "Forrige side" },
          on: { click: _vm.prevPage }
        },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-lg",
            attrs: { icon: "chevron-left" }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "text-lg text-grey-500" }, [
        _vm._v(_vm._s(_vm.currentPage) + " / " + _vm._s(_vm.totalPages))
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass:
            "ml-4 p-3 disabled:opacity-30 disabled:cursor-not-allowed",
          attrs: {
            disabled: _vm.currentPage === _vm.totalPages,
            title: "Neste side"
          },
          on: { click: _vm.nextPage }
        },
        [
          _c("font-awesome-icon", {
            staticClass: "fa-lg",
            attrs: { icon: "chevron-right" }
          })
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }