import { render, staticRenderFns } from "./answerForumThreadDialog.vue?vue&type=template&id=8400d0ee&"
import script from "./answerForumThreadDialog.vue?vue&type=script&lang=js&"
export * from "./answerForumThreadDialog.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("D:\\a\\1\\s\\src\\KoRus.Kommunetorget\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('8400d0ee')) {
      api.createRecord('8400d0ee', component.options)
    } else {
      api.reload('8400d0ee', component.options)
    }
    module.hot.accept("./answerForumThreadDialog.vue?vue&type=template&id=8400d0ee&", function () {
      api.rerender('8400d0ee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "Assets/Scripts/components/pages/forum/components/answerForumThreadDialog.vue"
export default component.exports