<template>
    <div class="bg-gray-100">
        <div v-if="content.content" class="border-2 article--mainbody" >
            <div v-if="content.heading" class="text-xl p-4 font-semibold">
                {{content.heading}}
            </div>
            <div v-html="content.content" class="p-4"></div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['content']
};
</script>
