<template>
    <div class="w-full py-6 articleItemVertical">
        <h3 class="font-bold text-lg text-textgray mb-2">
            <a
                :href="article.url"
                :title="article.heading"
                v-text="article.heading"
                class="no-underline hover:underline hover:text-green-500"
            ></a>
        </h3>
        <p class="text-sm text-textgray-light italic">
            Publisert:
            <template>{{ date }}</template>
            <template v-if="article.subHeading">
                &mdash; {{ article.subHeading }}
            </template>
        </p>
    </div>
</template>

<script>
export default {
    props: ['article'],
    computed: {
        date() {
            const d = new Date(this.article.startPublish);
            const day = ('0' + d.getDate()).slice(-2);
            const month = ('0' + (d.getMonth() + 1)).slice(-2);
            const year = d.getFullYear();
            return day + '.' + month + '.' + year;
        }
    }
};
</script>
