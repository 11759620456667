<template>
    <DefaultTemplate :model="model">
        <Breadcrumbs :breadcrumbs="model.breadcrumbs" />
        <div class="container lg:flex mb-10">
            <div class="w-full">
                <h1 v-if="model.name" v-text="model.name" class="font-bold text-5xl text-textgray lg:text-textgray-light mb-10"></h1>
                <div v-if="model.mainBody" v-html="model.mainBody"></div>
                <div v-if="loading">
                    <LoadingSpinner size="2x" />
                </div>
                <div v-else>
                    <div class="flex flex-row justify-end">
                        <button v-if="model.allowThreads" class="btn btn-blue" @click="handleOpenNewThreadDialog()">
                            <font-awesome-icon :icon="openNewThreadDialog ? 'times' : 'plus'" />
                            Legg til innlegg
                        </button>
                        <button v-if="model.allowForums" class="btn btn-blue" @click="handleOpenNewForumDialog()">
                            <font-awesome-icon :icon="openNewForumDialog ? 'times' : 'plus'" />
                            Legg til mappe
                        </button>
                        <!--
                        <button v-if="model.allowThreads" class="btn btn-red-text">
                            <font-awesome-icon icon="trash-alt" />
                            Slett denne mappen
                        </button>
                        -->
                    </div>
                    <div v-if="openNewForumDialog" class="border bg-blue-100 p-10 block mt-4">
                        <NewForumDialog :model="model" @clicked="handleOpenNewForumDialog" />
                    </div>
                    <div v-if="openNewThreadDialog" class="border bg-blue-100 p-10 block mt-4">
                        <NewThreadDialog :model="model" @clicked="handleOpenNewThreadDialog" />
                    </div>

                    <!-- Forums -->
                    <div class="mt-4 divide-y">
                        <template v-for="(forum, index) in forums.children">
                            <div :key="forum.id"
                                 v-if="index === 0 && forum.visible"
                                 class="flex divide-x bg-blue-100 text-blue-600">
                                <div class="w-full md:w-1/2 lg:w-1/4 p-2 font-bold text-lg">
                                    Forum
                                </div>
                                <div class="hidden md:block w-1/2 lg:w-1/4 p-2 font-bold text-lg">
                                    Siste oppdatert innlegg
                                </div>
                            </div>
                            <div :key="forum.id"
                                 v-if="forum.visible"
                                 class="md:flex py-2 md:divide-x"
                                 :class="index % 2 ? 'bg-gray-100' : 'bg-white'">
                                <div class="w-full md:w-1/2 lg:w-1/4 px-2">
                                    <font-awesome-icon icon="comments" class="mr-2" />
                                    <a :href="forum.url"
                                       class="underline hover:text-blue-500">
                                        <span> {{ forum.name }}</span>
                                    </a>
                                </div>

                                <div class="hidden md:block w-1/2 lg:w-3/4 px-2">
                                    <template v-if="forum.latestUpdatedThread">
                                        {{ new Date(forum.latestUpdatedThread.startPublish).toLocaleDateString('nb-NO', dateOptions) }}
                                        -
                                        <a :href="forum.latestUpdatedThread.url"
                                           class="underline hover:text-blue-500">
                                            {{forum.latestUpdatedThread.name}}
                                        </a>
                                    </template>
                                </div>
                            </div>
                        </template>
                    </div>
                    <!-- // Forums -->
                    <!-- Threads -->
                    <div class="mt-4">
                        <template v-for="forum in forums.children">
                            <template v-for="(thread, index) in forum">
                                <div v-if="thread && (thread.visible && thread.pageType !== 'ForumPage')"
                                     :key="thread.id+index"
                                     class="divide-y">
                                    <div v-if="index === 0"
                                         class="md:flex text-blue-600 bg-blue-100 font-bold text-lg divide-x">
                                        <div class="w-full md:w-2/4 p-2">
                                            Innlegg
                                        </div>

                                        <div class="hidden md:block w-1/4 p-2">
                                            Sist oppdatert
                                        </div>

                                        <div class="hidden md:block w-1/4 text-right p-2">
                                            Svar
                                        </div>
                                    </div>

                                    <ThreadListItem :thread="thread" :class="index % 2 ? 'bg-gray-100' : 'bg-white'" />
                                </div>
                            </template>
                        </template>
                    </div>
                    <!-- // Threads -->
                </div>
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';
import LoadingSpinner from '@/Scripts/components/atoms/LoadingSpinner/loadingSpinner.vue';

// Forum components
import ThreadListItem from '@/Scripts/components/pages/forum/ForumThreadListItem.vue';
import NewForumDialog from '@/Scripts/components/pages/forum/components/newForumDialog.vue';
import NewThreadDialog from '@/Scripts/components/pages/forum/components/newThreadDialog.vue';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
        LoadingSpinner,
        ThreadListItem,
        NewForumDialog,
        NewThreadDialog
    },
    data() {
        return {
            dateOptions: { year: 'numeric', month: '2-digit', day: '2-digit' },
            forums: {
                children: this.model.current.children,
                current: this.model.current
            },
            loading: false,
            forumName: '',
            allowThreads: false,
            allowForums: false,
            openNewForumDialog: false,
            openNewThreadDialog: false,
            threadTitle: '',
            threadText: ''
        };
    },
    methods: {
        handleOpenNewForumDialog() {
            this.setState({
                openNewForumDialog: !this.openNewForumDialog,
            });
        },
        handleOpenNewThreadDialog() {
            this.setState({
                openNewThreadDialog: !this.openNewThreadDialog,
            });
        },
        setState(obj) {
            Object.assign(this, obj);
        }
    }
};
</script>
