var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "flex flex-col space-y-4 w-full lg:max-w-5xl lg:mx-auto",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.createForum()
        }
      }
    },
    [
      _vm.loading
        ? [_c("LoadingSpinner", { attrs: { size: "2x" } })]
        : _vm._e(),
      _vm._v(" "),
      !_vm.error && !_vm.loading
        ? [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.forumName,
                  expression: "forumName"
                }
              ],
              staticClass: "border p-3 rounded bg-white",
              attrs: { placeholder: "Tittel" },
              domProps: { value: _vm.forumName },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.forumName = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            _c("div", { staticClass: "flex space-x-4" }, [
              _c("label", { attrs: { for: "allowThreads" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.allowThreads,
                      expression: "allowThreads"
                    }
                  ],
                  attrs: { id: "allowThreads", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.allowThreads)
                      ? _vm._i(_vm.allowThreads, null) > -1
                      : _vm.allowThreads
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.allowThreads,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.allowThreads = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.allowThreads = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.allowThreads = $$c
                      }
                    }
                  }
                }),
                _vm._v("\n                Tillat innlegg\n            ")
              ]),
              _vm._v(" "),
              _c("label", { attrs: { for: "allowForums" } }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.allowForums,
                      expression: "allowForums"
                    }
                  ],
                  attrs: { id: "allowForums", type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.allowForums)
                      ? _vm._i(_vm.allowForums, null) > -1
                      : _vm.allowForums
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.allowForums,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.allowForums = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.allowForums = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.allowForums = $$c
                      }
                    }
                  }
                }),
                _vm._v("\n                Tillat forum\n            ")
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c(
                "button",
                { staticClass: "btn btn-blue", attrs: { type: "submit" } },
                [_vm._v("Opprett forum")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-red",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.close()
                    }
                  }
                },
                [_vm._v("Avbryt")]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? [
            _c(
              "div",
              {
                staticClass:
                  "text-center bg-red-100 border border-red-200 text-red-600 p-4"
              },
              [
                _vm._v(
                  "\n            Beklager, det oppso en feil! Prøv igjen. Hvis problemet fortsetter vennligst ta kontakt med oss.\n        "
                )
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }