var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultTemplate",
    { attrs: { model: _vm.model } },
    [
      _c("Breadcrumbs", { attrs: { breadcrumbs: _vm.model.breadcrumbs } }),
      _vm._v(" "),
      _c("div", { staticClass: "container lg:flex mb-10" }, [
        _c(
          "div",
          { staticClass: "w-full lg:max-w-6xl mx-auto" },
          [
            _c("div", [
              _c(
                "form",
                {
                  staticClass: "flex mb-4",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.search()
                    }
                  }
                },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.query,
                        expression: "query"
                      }
                    ],
                    staticClass: "w-full flex-1 px-3 border",
                    attrs: { placeholder: "Søk i alt innhold" },
                    domProps: { value: _vm.query },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.query = $event.target.value
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    { staticClass: "btn btn-blue", attrs: { type: "submit" } },
                    [
                      _c("font-awesome-icon", { attrs: { icon: "search" } }),
                      _vm._v(
                        "\n                        Søk\n                    "
                      )
                    ],
                    1
                  )
                ]
              )
            ]),
            _vm._v(" "),
            _vm.loading
              ? _c("LoadingSpinner", { attrs: { size: "2x" } })
              : _vm._e(),
            _vm._v(" "),
            (_vm.pageResults.length > 0 || _vm.fileResults.length > 0) &&
            !_vm.loading
              ? _c("div", { staticClass: "md:flex" }, [
                  _c(
                    "div",
                    { staticClass: "w-full px-2 md:px-0" },
                    [
                      _c("div", { staticClass: "flex" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "py-4 px-10 border hover:text-gray-800 focus:outline-none",
                            class: [
                              _vm.tab === "pages"
                                ? "bg-white text-gray-800 border-gray-200"
                                : "bg-gray-100 text-gray-500 border-gray-200"
                            ],
                            attrs: {
                              title: "Bytt til liste over sider",
                              disabled: _vm.pageResults.length === 0
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeTab("pages")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                            Sider (" +
                                _vm._s(_vm.numHitsPages) +
                                ")\n                        "
                            )
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "py-4 px-10 border hover:text-gray-800 focus:outline-none",
                            class: [
                              _vm.tab === "files"
                                ? "bg-white text-gray-800 border-gray-200"
                                : "bg-gray-100 text-gray-500 border-gray-200"
                            ],
                            attrs: {
                              title: "Bytt til liste over filer",
                              disabled: _vm.fileResults.length === 0
                            },
                            on: {
                              click: function($event) {
                                $event.preventDefault()
                                return _vm.changeTab("files")
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                            Filer (" +
                                _vm._s(_vm.numHitsFiles) +
                                ")\n                        "
                            )
                          ]
                        )
                      ]),
                      _vm._v(" "),
                      _vm.tab === "pages"
                        ? [
                            _c(
                              "ol",
                              { staticClass: "divide-y bg-white border" },
                              _vm._l(_vm.pageResults, function(result) {
                                return _c(
                                  "li",
                                  { key: result.url, staticClass: "p-4" },
                                  [
                                    _c("PageResultItem", {
                                      attrs: { item: result }
                                    })
                                  ],
                                  1
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            Math.ceil(
                              _vm.numHitsPages / _vm.pagination.pages.pageSize
                            ) > 1
                              ? _c("Pagination", {
                                  attrs: {
                                    currentPage: _vm.pagination.pages.page,
                                    totalPages: Math.ceil(
                                      _vm.numHitsPages /
                                        _vm.pagination.pages.pageSize
                                    )
                                  },
                                  on: { change: _vm.paginatePages }
                                })
                              : _vm._e()
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.tab === "files"
                        ? [
                            _c(
                              "ol",
                              { staticClass: "divide-y bg-white border" },
                              _vm._l(_vm.fileResults, function(result) {
                                return _c(
                                  "li",
                                  { key: result.url, staticClass: "p-4" },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "md:flex md:justify-between text-base mb-0"
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass:
                                              "text-blue-500 underline hover:text-red-500",
                                            attrs: {
                                              href: result.url,
                                              title: result.title,
                                              taget: "_blank"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(result.title) +
                                                "\n                                    "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            Math.ceil(
                              _vm.numHitsFiles / _vm.pagination.files.pageSize
                            ) > 1
                              ? _c("Pagination", {
                                  attrs: {
                                    currentPage: _vm.pagination.files.page,
                                    totalPages: Math.ceil(
                                      _vm.numHitsFiles /
                                        _vm.pagination.files.pageSize
                                    )
                                  },
                                  on: { change: _vm.paginateFiles }
                                })
                              : _vm._e()
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.searchPerformed && _vm.totalHits === 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "bg-blue-100 border border-blue-200 text-blue-600 p-4"
                  },
                  [
                    _vm._v(
                      "\n                Beklager, vi fant ingen treff!\n            "
                    )
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.error
              ? _c(
                  "div",
                  {
                    staticClass:
                      "bg-red-100 border border-red-200 text-red-600 p-4"
                  },
                  [
                    _vm._v(
                      "\n                Beklager, det oppso en feil!\n            "
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }