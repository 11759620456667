var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      { staticClass: "p-4 mb-4" },
      [
        _c("div", { staticClass: "article--mainbody" }, [
          _vm.thread.Heading
            ? _c("div", {
                staticClass: "font-bold",
                domProps: { innerHTML: _vm._s(_vm.thread.Heading) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.thread.mainIntro
            ? _c("div", {
                domProps: { innerHTML: _vm._s(_vm.thread.mainIntro) }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.thread.mainBody
            ? _c("div", {
                domProps: { innerHTML: _vm._s(_vm.thread.mainBody) }
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "text-xs font-bold mt-4" }, [
          _vm._v(
            "Lagt inn " +
              _vm._s(_vm.dateFormated) +
              " av " +
              _vm._s(_vm.thread.createdBy)
          )
        ]),
        _vm._v(" "),
        !_vm.thread.IsLocked
          ? _c("div", { staticClass: "text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn-small btn-blue",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleAnswerDialog()
                    }
                  }
                },
                [
                  _c("font-awesome-icon", { attrs: { icon: _vm.buttonIcon } }),
                  _vm._v(" " + _vm._s(_vm.buttonText) + "\n            ")
                ],
                1
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.showAnswerDialog
          ? _c("AnswerThread", { attrs: { id: _vm.thread.id } })
          : _vm._e()
      ],
      1
    ),
    _vm._v(" "),
    _vm.thread.replies
      ? _c(
          "div",
          _vm._l(_vm.thread.replies, function(thread, index) {
            return _c("ForumThreadPost", {
              key: thread.id,
              staticClass: "pl-6 divide-y",
              class: index % 2 ? "bg-blue-100" : "bg-white",
              attrs: {
                thread: thread,
                buttonText: "Svar på denne kommentaren",
                buttonIcon: "comments",
                model: _vm.model
              }
            })
          }),
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }