var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-20" },
    [
      _c("HeaderLine", { attrs: { text: _vm.model.name } }),
      _vm._v(" "),
      _vm.model.view === "horizontal"
        ? _c(
            "div",
            {
              staticClass:
                "md:flex md:flex-wrap lg:flex-nowrap md:space-x-4 lg:space-x-6 mb-6"
            },
            [
              _vm._l(_vm.model.articles, function(article) {
                return [
                  _c("ArticleItemHorizontal", {
                    key: article.id,
                    attrs: { article: article }
                  })
                ]
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.model.view === "vertical"
        ? _c(
            "div",
            { staticClass: "mb-6 divide-y" },
            [
              _vm._l(_vm.model.articles, function(article) {
                return [
                  _c("ArticleItemVertical", {
                    key: article.id,
                    attrs: { article: article }
                  })
                ]
              })
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "text-center" }, [
        _c(
          "a",
          {
            staticClass: "btn btn-yellow",
            attrs: { href: _vm.model.buttonLink, title: _vm.model.buttonText }
          },
          [
            _vm._v(
              "\n            " + _vm._s(_vm.model.buttonText) + " →\n        "
            )
          ]
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }