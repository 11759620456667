var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        "form",
        [
          _c("label", { staticClass: "mb-4 block" }, [
            _c(
              "span",
              {
                staticClass:
                  "font-bold text-xl text-textgray lg:text-textgray-light mb-2 block border-b"
              },
              [_vm._v("\n            Last opp filer\n        ")]
            ),
            _vm._v(" "),
            _c("span", { staticClass: "text-sm" }, [
              _c("input", {
                ref: "files",
                attrs: { type: "file", id: "files", multiple: "" },
                on: {
                  change: function($event) {
                    return _vm.handleFileUploads()
                  }
                }
              })
            ])
          ]),
          _vm._v(" "),
          !_vm.error
            ? [
                _vm.loading ? _c("LoadingSpinner") : _vm._e(),
                _vm._v(" "),
                !_vm.loading
                  ? _c(
                      "button",
                      {
                        staticClass: "btn-small btn-blue",
                        class: _vm.files.length === 0 ? "btn-disabled" : "",
                        attrs: { disabled: _vm.files.length === 0 },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            return _vm.submitFiles()
                          }
                        }
                      },
                      [
                        _c("font-awesome-icon", { attrs: { icon: "upload" } }),
                        _vm._v("\n            Last opp\n        ")
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.error
            ? [
                _c(
                  "div",
                  {
                    staticClass:
                      "text-center bg-red-100 border border-red-200 text-red-600 p-4"
                  },
                  [
                    _vm._v(
                      "\n            Beklager, det oppso en feil! Prøv igjen. Hvis problemet fortsetter vennligst ta kontakt med oss.\n        "
                    )
                  ]
                )
              ]
            : _vm._e()
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }