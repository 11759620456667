var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "w-full py-6 articleItemVertical" }, [
    _c("h3", { staticClass: "font-bold text-lg text-textgray mb-2" }, [
      _c("a", {
        staticClass: "no-underline hover:underline hover:text-green-500",
        attrs: { href: _vm.article.url, title: _vm.article.heading },
        domProps: { textContent: _vm._s(_vm.article.heading) }
      })
    ]),
    _vm._v(" "),
    _c(
      "p",
      { staticClass: "text-sm text-textgray-light italic" },
      [
        _vm._v("\n        Publisert:\n        "),
        [_vm._v(_vm._s(_vm.date))],
        _vm._v(" "),
        _vm.article.subHeading
          ? [
              _vm._v(
                "\n            — " +
                  _vm._s(_vm.article.subHeading) +
                  "\n        "
              )
            ]
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }