var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "my-6" },
    _vm._l(_vm.items, function(item) {
      return _c("li", { key: item.id, staticClass: "mb-2" }, [
        _vm._v("\n        →\n        "),
        _c(
          "a",
          {
            staticClass:
              "text-blue-500 hover:text-red-500 underline hover:no-underline",
            attrs: { href: item.url, title: item.name }
          },
          [_vm._v("\n            " + _vm._s(item.name) + "\n        ")]
        )
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }