var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass:
        "flex flex-col space-y-4 w-full lg:max-w-5xl lg:mx-auto mt-4",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.createReply()
        }
      }
    },
    [
      _vm.loading
        ? [_c("LoadingSpinner", { attrs: { size: "2x" } })]
        : _vm._e(),
      _vm._v(" "),
      !_vm.error && !_vm.loading
        ? [
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.answerText,
                  expression: "answerText"
                }
              ],
              staticClass: "border p-3 rounded bg-white h-32",
              attrs: { placeholder: "Tekst" },
              domProps: { value: _vm.answerText },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.answerText = $event.target.value
                }
              }
            }),
            _vm._v(" "),
            !_vm.validated
              ? [
                  _c(
                    "div",
                    {
                      staticClass:
                        "text-center bg-blue-100 border border-blue-200 text-blue-600 p-4"
                    },
                    [
                      _vm._v(
                        "\n                Vennligst fyll ut tekstfeltet før du sender inn et svar.\n            "
                      )
                    ]
                  )
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "text-right" }, [
              _c(
                "button",
                {
                  staticClass: "btn-small",
                  class:
                    _vm.answerText.length === 0 ? "btn-disabled" : "btn-blue",
                  attrs: {
                    type: "submit",
                    disabled: _vm.answerText.length > 0 ? false : true
                  }
                },
                [_vm._v("\n                Svar\n            ")]
              )
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.error
        ? [
            _c(
              "div",
              {
                staticClass:
                  "text-center bg-red-100 border border-red-200 text-red-600 p-4"
              },
              [
                _vm._v(
                  "\n            Beklager, det oppso en feil! Prøv igjen. Hvis problemet fortsetter vennligst ta kontakt med oss.\n        "
                )
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }