<template>
    <div class="relative mb-2 mx-0">
        <div v-if="images.length > 1" class="slider bg-gray-50 py-2 px-0 md:p-4 -mx-4">
            <swiper ref="mySwiper" :options="swiperOptions">
                <swiper-slide
                    v-for="item in images"
                    :key="item.id"
                >
                    <img
                        :src="`${item.url}?width=1152&${item.crop}`"
                        :alt="item.description"
                        class="w-full"
                    />
                    <p
                        v-if="item.description"
                        v-text="item.description"
                        class="px-4 md:px-0 text-xs"
                    ></p>
                </swiper-slide>
                <template v-if="images.length > 1">
                    <button class="swiper-button swiper-button-prev" slot="button-prev"></button>
                    <button class="swiper-button swiper-button-next" slot="button-next"></button>
                </template>
                <div v-if="images.length > 1" class="swiper-pagination" slot="pagination"></div>
            </swiper>
        </div>
        <div v-else>
            <img
                :src="`${images[0].url}?width=1152&${images[0].crop}`"
                :alt="images[0].description"
                class="w-full"
            />
            <p
                v-if="images[0].description"
                v-text="images[0].description"
                class="text-xs"
            ></p>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper';

export default {
    name: 'carrousel',
    props: ['images'],
    components: {
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            swiperOptions: {
                loop: this.images.length > 1,
                centeredSlides: true,
                effect: 'slide',
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true,
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
            }
        };
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.$swiper;
        }
    }
};
</script>
