var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "mb-20" }, [
    _vm.headerline
      ? _c("div", [_c("HeaderLine", { attrs: { text: "Utforsk tema" } })], 1)
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "flex flex-wrap -mx-4" },
      _vm._l(_vm.themes, function(theme, index) {
        return _c(
          "div",
          { key: index, staticClass: "w-full md:w-1/2 lg:w-1/3 p-4" },
          [
            _c(
              "div",
              {
                staticClass:
                  "relative border rounded-b-lg shadow hover:shadow-lg h-full group"
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "border-t-8 p-6 group-hover:border-black",
                    class: theme.themeColor
                      ? "theme-border-" + theme.themeColor
                      : ""
                  },
                  [
                    _c("div", { staticClass: "flex items-center mb-6" }, [
                      theme.image
                        ? _c("div", { staticClass: "w-16 mr-1" }, [
                            _c("img", {
                              attrs: { src: theme.image, alt: theme.heading }
                            })
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          class: theme.themeColor
                            ? "theme-text-" + theme.themeColor
                            : ""
                        },
                        [
                          _c("span", { staticClass: "text-xs" }, [
                            _vm._v("Utforsk tema")
                          ]),
                          _vm._v(" "),
                          _c("h3", {
                            staticClass: "font-bold text-xl",
                            domProps: { innerHTML: _vm._s(theme.heading) }
                          })
                        ]
                      )
                    ]),
                    _vm._v(" "),
                    theme.intro
                      ? _c("p", {
                          staticClass: "text-base leading-6",
                          domProps: { innerHTML: _vm._s(theme.intro) }
                        })
                      : _vm._e()
                  ]
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass:
                      "absolute w-full h-full top-0 left-0 cursor-pointer",
                    attrs: { href: theme.url, title: theme.heading }
                  },
                  [_c("span", { staticClass: "sr-only" }, [_vm._v("Les mer")])]
                )
              ]
            )
          ]
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }