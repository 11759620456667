<template>
    <div>
        <div class="bg-blue-400 text-white border-b-8 border-yellow-400 py-4 mb-6">
            <header class="container relative">
                <div class="flex">
                    <!-- Logo -->
                    <div class="w-3/6">
                        <a href="/" title="Kommunetorget.no">
                            <img :src="logo" />
                        </a>
                    </div>
                    <!-- // Logo -->

                    <!-- Søkefelt -->
                    <div class="w-2/6 hidden lg:flex justify-end items-center">
                        <form class="w-3/4" action="/sok">
                            <fieldset>
                                <legend class="sr-only">
                                    Søkeskjema
                                </legend>
                                <label for="globalsearch" class="sr-only">Søkefelt</label>
                                <div class="relative">
                                    <input type="text" id="globalsearch" class="bg-blue-500 rounded p-3 pl-10 w-full" name="ord" placeholder="Søk i alt innhold" />
                                    <button type="submit" class="absolute left-3 top-3 text-blue-200">
                                        <span class="sr-only">Søk</span>
                                        <font-awesome-icon icon="search" />
                                    </button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                    <!-- // Søkefelt -->

                    <!-- Knapper -->
                    <div class="w-3/6 lg:w-1/6 flex justify-end items-center relative">
                        <a href="/sok/" title="Gå til søkesiden" class="btn btn-blue mr-3 inline-block lg:hidden">
                            <span class="sr-only">Gå til søkesiden</span>
                            <font-awesome-icon icon="search" />
                        </a>
                        <button
                            @click.prevent="showMenu = !showMenu"
                            class="btn btn-blue"
                            title="Trykk for å åpne/lukke menyen"
                        >
                            <font-awesome-icon v-if="!showMenu" icon="bars" class="mr-0 lg:mr-1"/>
                            <font-awesome-icon v-if="showMenu" icon="times" class="mr-0 lg:mr-1"/>
                            <span class="hidden lg:inline-block">Alt innhold</span>
                        </button>
                     </div>
                    <!-- // Knapper -->
                </div>

                <!-- Meny -->
                <nav v-if="showMenu" class="-top-4 lg:top-auto absolute -left-4 -right-4 z-50 lg:right-0 lg:left-auto lg:bg-white bg-purple-100 lg:rounded p-6 px-8 lg:p-4 text-gray-900 shadow-sm lg:shadow-md w-auto lg:w-full lg:max-w-md text-lg lg:text-sm">
                    <div class="triangle hidden lg:block"></div>
                    <div class="block lg:hidden text-right mt-1">
                        <button
                            @click.prevent="showMenu = !showMenu"
                            class="text-base inline-block py-2 px-3 rounded border border-blue-600 text-blue-600 bg-purple-100 hover:bg-white"
                        >
                            <span class="sr-only">Åpne/lukk menyen</span>
                            <font-awesome-icon icon="times" class="lg:mr-1"/>
                        </button>
                    </div>
                    <Menu :menu="model.menu"/>
                </nav>
                <!-- // Meny -->
            </header>
        </div>
    </div>
</template>

<script>
import Menu from '@/Scripts/components/organisms/Header/menu.vue';
export default {
    props: ['model'],
    components: {
        Menu
    },
    data() {
        return {
            showMenu: false,
            logo: '/Assets/Images/Kommunetorget/kt_logo_negativ21.png',
        };
    }
};
</script>

<style scoped>
    .triangle {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 20px 20px 20px;
        border-color: transparent transparent #ffffff transparent;
        position: absolute;
        top: -20px;
        right: 40px;
    }
</style>
