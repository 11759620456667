<template>
    <form @submit.prevent="createReply()" class="flex flex-col space-y-4 w-full lg:max-w-5xl lg:mx-auto mt-4">
        <template v-if="loading">
            <LoadingSpinner size="2x" />
        </template>
        <template v-if="!error && !loading">
            <textarea placeholder="Tekst" v-model="answerText" class="border p-3 rounded bg-white h-32"></textarea>
            <template v-if="!validated">
                <div class="text-center bg-blue-100 border border-blue-200 text-blue-600 p-4">
                    Vennligst fyll ut tekstfeltet før du sender inn et svar.
                </div>
            </template>
            <div class="text-right">
                <button
                    type="submit"
                    class="btn-small"
                    :class="answerText.length === 0 ? 'btn-disabled' : 'btn-blue'"
                    :disabled="answerText.length > 0 ? false : true"
                >
                    Svar
                </button>
            </div>
        </template>
        <template v-if="error">
            <div class="text-center bg-red-100 border border-red-200 text-red-600 p-4">
                Beklager, det oppso en feil! Prøv igjen. Hvis problemet fortsetter vennligst ta kontakt med oss.
            </div>
        </template>
    </form>
</template>

<script>
import api from '@/Scripts/api/api';
import LoadingSpinner from '@/Scripts/components/atoms/LoadingSpinner/loadingSpinner.vue';

export default {
    props: ['id'],
    data() {
        return {
            loading: false,
            answerText: '',
            error: false,
            validated: true
        };
    },
    components: {
        LoadingSpinner
    },
    methods: {
        createReply() {
            const self = this;
            self.loading = true;
            self.validated = true;

            if (self.validate(self.answerText)) {
                const body = {
                    parentId: self.id,
                    text: self.answerText
                };
                api.createReply(body).then(response => {
                    if (response.status === 200) {
                        location.reload();
                        self.answerText = '';
                        self.loading = false;
                    } else {
                        self.loading = false;
                        self.error = true;
                    }
                });
            } else {
                self.validated = false;
                self.error = false;
                self.loading = false;
            }
        },
        close() {
            this.$emit('clicked', 'someValue');
        },
        validate(text) {
            const string = text.replace(/\s/g,'');
            if (string.length > 0) {
                return true;
            }
            return false;
        }
    }
};
</script>
