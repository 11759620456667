<template>
    <DefaultTemplate :model="model">
        <Breadcrumbs :breadcrumbs="model.breadcrumbs" />
        <div class="container lg:flex mb-10">
            <div class="w-full max-w-6xl mx-auto">

                <article-body :model="model">
                    <section v-if="model.articles" class="divide-y">
                        <template v-for="article in model.articles">
                            <ArticleItemVertical :article="article" :key="article.id" />
                        </template>
                    </section>
                </article-body>
            </div>
        </div>
</DefaultTemplate>
</template>

<script>
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';
import ArticleBody from '@/Scripts/components/organisms/Article/Article.vue';
import ArticleItemVertical from '@/Scripts/components/blocks/ArticleListBlock/ArticleItemVertical.vue';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
        ArticleBody,
        ArticleItemVertical
    }
};
</script>
