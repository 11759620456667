<template>
    <form @submit.prevent="createThread()" class="flex flex-col space-y-4 w-full lg:max-w-5xl lg:mx-auto">
        <template v-if="loading">
            <LoadingSpinner size="2x" />
        </template>
        <template v-if="!error && !loading">
            <input placeholder="Tittel" v-model="threadTitle" class="border p-3 rounded bg-white"/>
            <textarea placeholder="Tekst" v-model="threadText" class="border p-3 rounded bg-white h-48"></textarea>
            <div class="text-right">
                <button type="submit" class="btn btn-blue">Opprett innlegg</button>
                <button type="button" class="btn btn-red" @click.prevent="close()">Avbryt</button>
            </div>
        </template>
        <template v-if="error">
            <div class="text-center bg-red-100 border border-red-200 text-red-600 p-4">
                Beklager, det oppso en feil! Prøv igjen. Hvis problemet fortsetter vennligst ta kontakt med oss.
            </div>
        </template>
    </form>
</template>

<script>
import api from '@/Scripts/api/api';
import LoadingSpinner from '@/Scripts/components/atoms/LoadingSpinner/loadingSpinner.vue';

export default {
    props: ['model'],
    components: {
        LoadingSpinner
    },
    data() {
        return {
            threadTitle: '',
            threadText: '',
            error: false,
            loading: false
        };
    },
    methods: {
        createThread() {
            const self = this;
            self.loading = true;
            const body = {
                parentId: self.model.contentLink.id,
                heading: self.threadTitle,
                text: self.threadText
            };
            api.createThread(body).then(response => {
                self.loading = false;
                if (response.status === 200) {
                    self.threadTitle = '';
                    self.threadText = '';
                    self.loading = false;
                    location.reload();
                } else {
                    self.error = true;
                }
            });
        },
        close() {
            this.$emit('clicked', 'someValue');
        }
    }
};
</script>
