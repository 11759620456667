<template>
    <div class="mb-20">
        <HeaderLine :text="model.name" />

        <div v-if="model.view === 'horizontal'" class="md:flex md:flex-wrap lg:flex-nowrap md:space-x-4 lg:space-x-6 mb-6">
            <template v-for="article in model.articles">
                <ArticleItemHorizontal :article="article" :key="article.id" />
            </template>
        </div>

        <div v-if="model.view === 'vertical'" class="mb-6 divide-y">
            <template v-for="article in model.articles">
                <ArticleItemVertical :article="article" :key="article.id" />
            </template>
        </div>

        <div class="text-center">
            <a
                :href="model.buttonLink"
                :title="model.buttonText"
                class="btn btn-yellow"
            >
                {{ model.buttonText }} &rarr;
            </a>
        </div>
    </div>
</template>

<script>
import HeaderLine from '@/Scripts/components/atoms/HeaderLine/HeaderLine.vue';
import ArticleItemHorizontal from '@/Scripts/components/blocks/ArticleListBlock/ArticleItemHorizontal.vue';
import ArticleItemVertical from '@/Scripts/components/blocks/ArticleListBlock/ArticleItemVertical.vue';
export default {
    props: ['model'],
    components: {
        HeaderLine,
        ArticleItemHorizontal,
        ArticleItemVertical
    }
};
</script>
