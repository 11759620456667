var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative mb-2 mx-0" }, [
    _vm.images.length > 1
      ? _c(
          "div",
          { staticClass: "slider bg-gray-50 py-2 px-0 md:p-4 -mx-4" },
          [
            _c(
              "swiper",
              { ref: "mySwiper", attrs: { options: _vm.swiperOptions } },
              [
                _vm._l(_vm.images, function(item) {
                  return _c("swiper-slide", { key: item.id }, [
                    _c("img", {
                      staticClass: "w-full",
                      attrs: {
                        src: item.url + "?width=1152&" + item.crop,
                        alt: item.description
                      }
                    }),
                    _vm._v(" "),
                    item.description
                      ? _c("p", {
                          staticClass: "px-4 md:px-0 text-xs",
                          domProps: { textContent: _vm._s(item.description) }
                        })
                      : _vm._e()
                  ])
                }),
                _vm._v(" "),
                _vm.images.length > 1
                  ? [
                      _c("button", {
                        staticClass: "swiper-button swiper-button-prev",
                        attrs: { slot: "button-prev" },
                        slot: "button-prev"
                      }),
                      _vm._v(" "),
                      _c("button", {
                        staticClass: "swiper-button swiper-button-next",
                        attrs: { slot: "button-next" },
                        slot: "button-next"
                      })
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.images.length > 1
                  ? _c("div", {
                      staticClass: "swiper-pagination",
                      attrs: { slot: "pagination" },
                      slot: "pagination"
                    })
                  : _vm._e()
              ],
              2
            )
          ],
          1
        )
      : _c("div", [
          _c("img", {
            staticClass: "w-full",
            attrs: {
              src: _vm.images[0].url + "?width=1152&" + _vm.images[0].crop,
              alt: _vm.images[0].description
            }
          }),
          _vm._v(" "),
          _vm.images[0].description
            ? _c("p", {
                staticClass: "text-xs",
                domProps: { textContent: _vm._s(_vm.images[0].description) }
              })
            : _vm._e()
        ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }