<template>
    <DefaultTemplate :model="model">
        <Breadcrumbs :breadcrumbs="model.breadcrumbs" />
        <div class="container lg:flex mb-10">
            <div class="w-full lg:max-w-6xl mx-auto">
                <div>
                    <form @submit.prevent="search()" class="flex mb-4">
                        <input v-model="query" class="w-full flex-1 px-3 border" placeholder="Søk i alt innhold" />
                        <button type="submit" class="btn btn-blue">
                            <font-awesome-icon icon="search" />
                            Søk
                        </button>
                    </form>
                </div>

                <LoadingSpinner v-if="loading" size="2x" />

                <div v-if="(pageResults.length > 0 || fileResults.length > 0) && !loading" class="md:flex">
                    <!-- Results -->
                    <div class="w-full px-2 md:px-0">
                        <!-- Tab -->
                        <div class="flex">
                            <button @click.prevent="changeTab('pages')"
                                    class="py-4 px-10 border hover:text-gray-800 focus:outline-none"
                                    :class="[tab === 'pages' ? 'bg-white text-gray-800 border-gray-200' : 'bg-gray-100 text-gray-500 border-gray-200']"
                                    title="Bytt til liste over sider"
                                    :disabled="pageResults.length === 0">
                                Sider ({{numHitsPages}})
                            </button>
                            <button @click.prevent="changeTab('files')"
                                    class="py-4 px-10 border hover:text-gray-800 focus:outline-none"
                                    :class="[tab === 'files' ? 'bg-white text-gray-800 border-gray-200' : 'bg-gray-100 text-gray-500 border-gray-200']"
                                    title="Bytt til liste over filer"
                                    :disabled="fileResults.length === 0">
                                Filer ({{numHitsFiles}})
                            </button>
                        </div>
                        <!-- // Tab -->
                        <!-- Pages -->
                        <template v-if="tab === 'pages'">
                            <ol class="divide-y bg-white border">
                                <li v-for="result in pageResults"
                                    :key="result.url"
                                    class="p-4">
                                    <PageResultItem :item="result" />
                                </li>
                            </ol>
                            <Pagination v-if="Math.ceil(numHitsPages / pagination.pages.pageSize) > 1" :currentPage="pagination.pages.page" :totalPages="Math.ceil(numHitsPages / pagination.pages.pageSize)" @change="paginatePages" />
                        </template>
                        <!-- // Pages -->
                        <!-- Files -->
                        <template v-if="tab === 'files'">
                            <ol class="divide-y bg-white border">
                                <li v-for="result in fileResults"
                                    :key="result.url"
                                    class="p-4">
                                    <h3 class="md:flex md:justify-between text-base mb-0">
                                        <a :href="result.url"
                                           :title="result.title"
                                           class="text-blue-500 underline hover:text-red-500"
                                           taget="_blank">
                                            {{ result.title }}
                                        </a>
                                    </h3>
                                </li>
                            </ol>
                            <Pagination v-if="Math.ceil(numHitsFiles / pagination.files.pageSize) > 1" :currentPage="pagination.files.page" :totalPages="Math.ceil(numHitsFiles / pagination.files.pageSize)" @change="paginateFiles" />
                        </template>
                        <!-- // Files -->
                    </div>
                </div>

                <!-- Message - No hits -->
                <div v-if="searchPerformed && totalHits === 0"
                     class="bg-blue-100 border border-blue-200 text-blue-600 p-4">
                    Beklager, vi fant ingen treff!
                </div>
                <!-- // Message - No hits -->
                <!-- Message - Error -->
                <div v-if="error"
                     class="bg-red-100 border border-red-200 text-red-600 p-4">
                    Beklager, det oppso en feil!
                </div>
                <!-- // Message - Error -->

            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import api from '@/Scripts/api/api';
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';
import LoadingSpinner from '@/Scripts/components/atoms/LoadingSpinner/loadingSpinner.vue';
import Pagination from '@/Scripts/components/organisms/Pagination/pagination.vue';
import PageResultItem from '@/Scripts/components/atoms/Search/pageResultItem.vue';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
        LoadingSpinner,
        Pagination,
        PageResultItem
    },
    data() {
        return {
            loading: false,
            error: false,
            query: null,
            searchPerformed: false,
            pageResults: [],
            fileResults: [],
            tab: 'pages',
            totalHits: 0,
            numHitsPages: 0,
            numHitsFiles: 0,
            pagination: {
                pages: {
                    page: 1,
                    pageSize: 20
                },
                files: {
                    page: 1,
                    pageSize: 20
                }
            },
        };
    },
    methods: {
        changeTab(tab) {
            this.tab = tab;
        },
        search() {
            this.loading = true;
            this.error = false;
            this.searchPerformed = false;
            this.totalHits = 0;
            const body = {
                words: this.query,
                pageNumberPages: this.pagination.pages.page - 1, // In the pagination, the first page must be 1 while in the search it must be 0. Therefore, subtract 1 here to get the correct page in the search
                pageNumberFiles: this.pagination.files.page - 1, // In the pagination, the first page must be 1 while in the search it must be 0. Therefore, subtract 1 here to get the correct page in the search
                pageSize: 20,
                matchAllWords: true,
                searchInFiles: true,
                siteId: ''
            };
            api.postSearch(body).then(response => {
                this.loading = false;
                this.searchPerformed = true;
                const data = response.data;
                if (!data.error) {
                    this.pageResults = data.Pages;
                    this.fileResults = data.Files;
                    this.numHitsFiles = data.TotalNumerberOfFiles;
                    this.numHitsPages = data.TotalNumerberOfPages;
                    this.totalHits = data.TotalNumerberOfFiles + data.TotalNumerberOfPages;
                }
            }).catch(error => {
                this.loading = false;
                this.error = true;
            });
        },
        paginatePages(direction) {
            if (direction < this.pagination.pages.page) {
                // Prev
                this.pagination.pages.page--;
            } else {
                // Next
                this.pagination.pages.page++;
            }
            this.search();
        },
        paginateFiles(direction) {
            if (direction < this.pagination.pages.page) {
                // Prev
                this.pagination.pages.page--;
            } else {
                // Next
                this.pagination.pages.page++;
            }
            this.search();
        },
        getQuery() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            let query = urlParams.get('ord');
            if (!query) {
                query = urlParams.get('q');
            }
            this.query = query;
            if (query) {
                this.search();
            }
        }
    },
    mounted() {
        this.getQuery();
    }
};
</script>
