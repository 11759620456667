<template>
    <div class="pagination flex flex-row justify-center items-center mt-4">
        <button
            @click="prevPage"
            :disabled="currentPage === 1"
            class="mr-4 p-3 disabled:opacity-30 disabled:cursor-not-allowed"
            title="Forrige side"
        >
            <font-awesome-icon icon="chevron-left" class="fa-lg" />
        </button>
        <div class="text-lg text-grey-500">{{ currentPage }} / {{ totalPages }}</div>
        <button
            @click="nextPage"
            :disabled="currentPage === totalPages"
            class="ml-4 p-3 disabled:opacity-30 disabled:cursor-not-allowed"
            title="Neste side"
        >
            <font-awesome-icon icon="chevron-right" class="fa-lg" />
        </button>
    </div>
</template>

<script>
export default {
    props: {
        currentPage: Number,
        totalPages: Number
    },
    methods: {
        nextPage() {
            const next = this.currentPage < this.totalPages ? (this.currentPage + 1) : this.totalPages;
            this.$emit('change', next, true);
        },
        prevPage() {
            const prev = this.currentPage > 1 ? (this.currentPage - 1) : this.currentPage;
            this.$emit('change', prev, true);
        }
    }
};
</script>
