import axios from 'axios';

const get = (baseURL, url, parameters, headers) => {
    return axios({
        method: 'get',
        baseURL: baseURL,
        url: url,
        params: parameters,
        headers: Object.assign({}, headers)
    });
};

const post = (baseURL, url, body) => {
    const defaultOptions = {
        baseURL: baseURL,
        method: 'post',
        headers: {
            'Content-Type': 'application/json',
        },
    };
    let instance = axios.create(defaultOptions);
    return instance.post(url, body);
};

const postData = (baseURL, url, data) => {
    return axios.post(
        baseURL + url,
        data,
        {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }
    );
};


const applicationPath = document.documentElement.dataset.applicationPath;

export default {
    /**
     * Getting content with the content link is the default way of calling the ContentDeliveryAPI.
     * It is used in MusicFestival to get:
     *  - block data
     *  - updated data after a `beta/contentSaved` message, which has the content link
     */
    getContentByContentLink: (contentLink, parameters, headers) => {
        if (headers == null) {
            headers = { 'Accept-Language': '' };
        }
        get(`${applicationPath}api/episerver/v2.0/`, `content/${contentLink}`, parameters);
    },

    /**
     * Getting data from ContentDeliveryAPI through regular routing (friendly URLs) was added in ContentDeliveryAPI 2.3.0.
     * It is used in MusicFestival to get:
     *  - page data, through the vuex `epiDataModel` module
     */
    getContentByFriendlyUrl: (friendlyUrl, parameters) =>
        get('/', friendlyUrl, parameters, { Accept: 'application/json' }),

    /**
     * Getting the children of the page with ContentDeliveryAPI is enabled by
     * the extensions in Infrastructure/ContentDeliveryExtendedRouting.
     * It is used in MusicFestival to get:
     *  - artist list in ArtistContainerPage.vue
     */
    getChildren(friendlyUrl, parameters) {
        // Split URL into path and queries
        const urlPieces = friendlyUrl.split('?');
        // In View mode we might visit the URL with or without a trailing / (i.e. "http://localhost:56312/en/artists" or "http://localhost:56312/en/artists/")
        const pathname = (urlPieces[0].endsWith('/') ? urlPieces[0] : urlPieces[0] + '/');
        // In Edit mode we'll have URL queries (i.e. "/EPiServer/CMS/Content/en/artists,,6/?epieditmode=True")
        const queries = urlPieces[1] ? '?' + urlPieces[1] : '';

        // Concatenate the friendly URL with "/children" for the Content API
        const callUrl = pathname + 'children' + queries;

        return this.getContentByFriendlyUrl(callUrl, parameters);
    },

    /**
    * Search
    */
    postSearch: (body) =>
        post(applicationPath, 'api/searchapi/search', body),

    /**
    * Forum
    */
    getForums: (parameters) =>
        // get(`${applicationPath}api/forumapi/getForum?id=` + parameters.parentId),
        get(`${applicationPath}api/forumapi/getforum?id=` + parameters.parentId),

    getForumThread: (parameters) =>
        get(`${applicationPath}api/forumapi/getforumthread?threadId=` + parameters.parentId),

    createForum: (body) =>
        post(applicationPath, 'api/forumapi/createforum', body),

    createThread: (body) =>
        post(applicationPath, 'api/forumapi/createthread', body),

    createReply: (body) =>
        post(applicationPath, 'api/forumapi/createreply', body),

    createThreadDocument: (data, id) => {
        return postData(applicationPath, 'api/forumapi/createThreadDocument?threadid=' + id, data);
    },
};
