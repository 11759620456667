<template>
    <div>
        Del denne siden:<br />
        <a :href="shareFacebookUrl" target="_blank" style="color: #4267B2;" class="hover:text-blue-500 text-3xl" title="Del på Facebook">
            <font-awesome-icon :icon="[ 'fab', 'facebook-square' ]" content="Del på Facebook" v-tippy="{ placement : 'bottom' }" />
            <span class="sr-only">
                Del denne siden på Facebook (åpnes i nytt vindu)
            </span>
        </a>
        <a :href="shareTwitterUrl" target="_blank" style="color: #1DA1F2;" class="hover:text-blue-500 text-3xl" title="Del på Twitter">
            <font-awesome-icon :icon="[ 'fab', 'twitter-square' ]" content="Del på Twitter" v-tippy="{ placement : 'bottom' }" />
            <span class="sr-only">
                Del denne siden på Twitter (åpnes i nytt vindu)
            </span>
        </a>
        <a href="#" @click.prevent="print()" class="text-grey-400 hover:text-blue-500 text-3xl" title="Skriv ut denne siden">
            <font-awesome-icon icon="print" content="Skriv ut denne siden" v-tippy="{ placement : 'bottom' }" />
            <span class="sr-only">
                Skriv ut denne siden
            </span>
        </a>
    </div>
</template>

<script>
export default {
    props: ['title'],
    computed: {
        shareFacebookUrl() {
            return 'https://www.facebook.com/sharer/sharer.php?u=' + window.location.origin + window.location.pathname;
        },
        shareTwitterUrl() {
            return 'http://twitter.com/intent/tweet?text=' + this.title + '&url=' + window.location.origin + window.location.pathname;
        }
    },
    methods: {
        print() {
            window.print();
        }
    }
};
</script>
