<template>
    <section>
        <div class="p-4 mb-4">
            <div class="article--mainbody">
                <div
                    v-if="thread.Heading"
                    v-html="thread.Heading"
                    class="font-bold"
                ></div>
                <div
                    v-if="thread.mainIntro"
                    v-html="thread.mainIntro"
                ></div>
                <div
                    v-if="thread.mainBody"
                    v-html="thread.mainBody"
                ></div>
            </div>
            <p class="text-xs font-bold mt-4">Lagt inn {{ dateFormated }} av {{ thread.createdBy }}</p>
            <div v-if="!thread.IsLocked" class="text-right">
                <button
                    type="button"
                    class="btn-small btn-blue"
                    @click.prevent="toggleAnswerDialog()"
                >
                    <font-awesome-icon :icon='buttonIcon' /> {{ buttonText }}
                </button>
            </div>

            <AnswerThread v-if="showAnswerDialog" :id="thread.id" />

        </div>
        <div v-if="thread.replies">
           <ForumThreadPost
                v-for="(thread, index) in thread.replies"
                :thread="thread"
                :key="thread.id"
                :class="index % 2 ? 'bg-blue-100' : 'bg-white'"
                :buttonText="`Svar på denne kommentaren`"
                :buttonIcon="'comments'"
                class="pl-6 divide-y"
                :model="model"
            />
        </div>
    </section>
</template>
<script>
import AnswerThread from '@/Scripts/components/pages/forum/components/answerForumThreadDialog.vue';
export default {
    name: 'ForumThreadPost',
    props: ['model','thread','buttonText','buttonIcon'],
    data() {
        return {
            showAnswerDialog: false
        };
    },
    components: {
        AnswerThread
    },
    computed: {
        dateFormated() {
            const d = new Date(this.thread.startPublish);
            const day = ('0' + d.getDate()).slice(-2);
            const month = ('0' + (d.getMonth() + 1)).slice(-2);
            const year = d.getFullYear();
            return day + '.' + month + '.' + year;
        }
    },
    methods: {
        toggleAnswerDialog() {
            this.showAnswerDialog = !this.showAnswerDialog;
        }
    }
};
</script>
