var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mb-20" },
    [
      _c("figure", { staticClass: "relative w-full m-0" }, [
        _c(
          "svg",
          {
            staticClass: "block",
            attrs: {
              version: "1.1",
              viewBox: "0 0 1152 648",
              preserveAspectRatio: "xMinYMin meet"
            }
          },
          [
            _c("image", {
              attrs: {
                href: _vm.model.image,
                x: "0",
                y: "0",
                width: "100%",
                height: "100%"
              }
            }),
            _vm._v(" "),
            _vm._l(_vm.model.shapes, function(shape, index) {
              return _c(
                "g",
                {
                  key: _vm.$uuid.v4(index),
                  staticClass: "hover_group",
                  attrs: { opacity: "0" }
                },
                [
                  _c("rect", {
                    staticClass: "cursor-pointer",
                    attrs: {
                      x: _vm.coordinates(shape.coordinates, "x"),
                      y: _vm.coordinates(shape.coordinates, "y"),
                      opacity: "1",
                      fill: "transparent",
                      width: _vm.size(shape.coordinates, "width"),
                      height: _vm.size(shape.coordinates, "height")
                    },
                    on: {
                      click: function($event) {
                        return _vm.onClickButton(shape.title, shape.description)
                      }
                    }
                  })
                ]
              )
            })
          ],
          2
        )
      ]),
      _vm._v(" "),
      _c("Content", { attrs: { content: _vm.content } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }