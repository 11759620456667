<template>
    <div class="mb-20">

        <figure class="relative w-full m-0">
            <svg version="1.1" viewBox="0 0 1152 648" class="block" preserveAspectRatio="xMinYMin meet">
                <image :href="model.image" x="0" y="0" width="100%" height="100%" />
                <g
                    v-for="(shape, index) in model.shapes"
                    :key="$uuid.v4(index)"
                    class="hover_group"
                    opacity="0"
                >
                    <rect
                    :x="coordinates(shape.coordinates, 'x')"
                    :y="coordinates(shape.coordinates, 'y')"
                    opacity="1"
                    fill="transparent"
                    :width="size(shape.coordinates, 'width')"
                    :height="size(shape.coordinates, 'height')"
                    v-on:click="onClickButton(shape.title, shape.description)"
                    class="cursor-pointer"
                    ></rect>
                </g>
            </svg>
        </figure>
        <Content :content="content" />
    </div>
</template>

<script>
import Content from './components/content.vue';
export default {
    props: ['model'],
    components: {
        Content
    },
    data() {
        return {
            content: {
                heading: null,
                content: null
            }
        };
    },
    methods: {
        onClickButton(heading, description) {
            this.content = {
                heading: heading,
                content: description
            };
        },
        removeWhiteSpace(string) {
            return string.replace(/\s/g,'');
        },
        coordinates(element, type) {
            const coordinates = this.removeWhiteSpace(element);
            const cord = type === 'x' ? 0 : 1;
            return coordinates.split(',')[cord];
        },
        size(element, type) {
            const size = this.removeWhiteSpace(element);
            const cord = type === 'height' ? 2 : 3;
            return size.split(',')[cord];
        }
    },
    computed: {

    }
};
</script>

<style scoped>
.hover_group:hover {
  opacity: 1;
  stroke-width: 4;
  stroke:rgb(43, 222, 221);
}
</style>
