<template>
    <DefaultTemplate :model="model">
        <div class="container" v-show="inEditMode">
            <!-- header er satt til schafolded(false) i epi så det er ingen vits å prøve å legge den til her, for den kan ikke redigeres -->
            <!-- <EpiProperty propertyName="Header" displayText="Header" /> -->
            <EpiProperty propertyName="Footer" displayText="Footer" />
            <EpiProperty propertyName="HighlightedTopicReference" displayText="Fremhevet tema" />
        </div>
        <div class="container">
            <HighlightedTopic v-if="model.highlightedTopic" :topic="model.highlightedTopic" />

            <div>
                <ContentArea v-epi-edit="'MainContent'" :model="model.mainContent" />
            </div>

            <div v-show="inEditMode">
                <ContentArea v-epi-edit="'TopicsContent'" :model="model.TopicsContent" />
            </div>
            <ExploreThemes :themes="model.topics" :headerline="true" />

            <div>
                <ContentArea v-epi-edit="'SecondaryContent'" :model="model.secondaryContent" />
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import ContentArea from '@/Scripts/components/ContentArea.vue';
import HighlightedTopic from '@/Scripts/components/blocks/HighlightedTopic/HighlightedTopic.vue';
import ExploreThemes from '@/Scripts/components/blocks/ExploreThemes/ExploreThemes.vue';
import EpiProperty from '@/Scripts/components/EpiProperty.vue';
import { mapState } from 'vuex';

export default {
    props: ['model'],
    computed: mapState({
        inEditMode: state => state.epiContext.inEditMode
    }),
    components: {
        ContentArea,
        HighlightedTopic,
        ExploreThemes,
        EpiProperty
    }
};
</script>
