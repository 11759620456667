<template>
    <form v-if="!loading">
        <label class="mb-4 block">
            <span class="font-bold text-xl text-textgray lg:text-textgray-light mb-2 block border-b">
                Last opp filer
            </span>
            <span class="text-sm">
                <input type="file" id="files" ref="files" multiple v-on:change="handleFileUploads()" />
            </span>
        </label>
        <template v-if="!error">
            <LoadingSpinner v-if="loading" />
            <button
                v-if="!loading"
                @click.prevent="submitFiles()"
                :disabled="files.length === 0"
                :class="files.length === 0 ? 'btn-disabled' : ''"
                class="btn-small btn-blue"
            >
                <font-awesome-icon icon="upload" />
                Last opp
            </button>
        </template>
        <template v-if="error">
            <div class="text-center bg-red-100 border border-red-200 text-red-600 p-4">
                Beklager, det oppso en feil! Prøv igjen. Hvis problemet fortsetter vennligst ta kontakt med oss.
            </div>
        </template>
    </form>
</template>

<script>
import api from '@/Scripts/api/api';
import LoadingSpinner from '@/Scripts/components/atoms/LoadingSpinner/loadingSpinner.vue';

export default {
    props: ['id'],
    components: {
        LoadingSpinner
    },
    data() {
        return {
            files: [],
            loading: false,
            error: false
        };
    },
    methods: {
        handleFileUploads() {
            this.files = this.$refs.files.files;
        },
        submitFiles() {
            const self = this;
            self.loading = true;
            self.error = false;

            let formData = new FormData();
            for (var i = 0; i < this.files.length; i++) {
                let file = this.files[i];
                formData.append('files[' + i + ']', file);
            }

            api.createThreadDocument(formData, self.id).then(response => {
                self.loading = false;
                console.log('responsen:', response);
                if (response.status === 200) {
                    self.loading = false;
                    self.files = [];
                    location.reload();
                } else {
                    self.error = true;
                }
            });
        }
    }
};
</script>
