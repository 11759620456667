var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    { staticClass: "article" },
    [
      _vm.model.heading || _vm.inEditMode
        ? _c("h1", {
            directives: [
              {
                name: "epi-edit",
                rawName: "v-epi-edit",
                value: "Heading",
                expression: "'Heading'"
              }
            ],
            staticClass:
              "font-bold text-5xl text-textgray lg:text-textgray-light mb-10",
            domProps: { textContent: _vm._s(_vm.model.heading) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.model.mainIntro || _vm.inEditMode
        ? _c("div", {
            directives: [
              {
                name: "epi-edit",
                rawName: "v-epi-edit",
                value: "MainIntro",
                expression: "'MainIntro'"
              }
            ],
            staticClass: "article--ingress font-bold leading-7 mb-4",
            domProps: { innerHTML: _vm._s(_vm.model.mainIntro) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.model.images.length > 0 || _vm.inEditMode
        ? _c("Slider", {
            directives: [
              {
                name: "epi-edit",
                rawName: "v-epi-edit",
                value: "Images",
                expression: "'Images'"
              }
            ],
            attrs: { images: _vm.model.images }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.inEditMode
        ? _c(
            "div",
            [
              _c("EpiProperty", {
                attrs: { propertyName: "RightImage", displayText: "Hovedbilde" }
              }),
              _vm._v(" "),
              _c("EpiProperty", {
                attrs: {
                  propertyName: "RightImageText",
                  displayText: "Bildetekst"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.model.mainBody || _vm.inEditMode
        ? _c("div", {
            directives: [
              {
                name: "epi-edit",
                rawName: "v-epi-edit",
                value: "MainBody",
                expression: "'MainBody'"
              }
            ],
            staticClass: "article--mainbody",
            domProps: { innerHTML: _vm._s(_vm.model.mainBody) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("ContentArea", {
        directives: [
          {
            name: "epi-edit",
            rawName: "v-epi-edit",
            value: "MainContentArea",
            expression: "'MainContentArea'"
          }
        ],
        attrs: { model: _vm.model.mainContentArea }
      }),
      _vm._v(" "),
      _vm.model.secondaryBody || _vm.inEditMode
        ? _c("div", {
            directives: [
              {
                name: "epi-edit",
                rawName: "v-epi-edit",
                value: "SecondaryBody",
                expression: "'SecondaryBody'"
              }
            ],
            staticClass: "article--mainbody",
            domProps: { innerHTML: _vm._s(_vm.model.secondaryBody) }
          })
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }