var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "highlight p-4 -mx-4 my-4 bg-blue-100" }, [
    _c(
      "a",
      {
        staticClass:
          "text-lg lg:text-base text-purple-500 font-bold no-underline hover:underline",
        attrs: { href: _vm.item.url, title: _vm.item.text }
      },
      [_vm._v("\n        " + _vm._s(_vm.item.text) + "\n    ")]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }