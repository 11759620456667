var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultTemplate",
    { attrs: { model: _vm.model } },
    [
      _c("Breadcrumbs", { attrs: { breadcrumbs: _vm.model.breadcrumbs } }),
      _vm._v(" "),
      _c("div", { staticClass: "container lg:flex mb-10" }, [
        _c(
          "div",
          { staticClass: "w-full max-w-6xl mx-auto" },
          [
            _c("article-body", { attrs: { model: _vm.model } }, [
              _vm.model.articles
                ? _c(
                    "section",
                    { staticClass: "divide-y" },
                    [
                      _vm._l(_vm.model.articles, function(article) {
                        return [
                          _c("ArticleItemVertical", {
                            key: article.id,
                            attrs: { article: article }
                          })
                        ]
                      })
                    ],
                    2
                  )
                : _vm._e()
            ])
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }