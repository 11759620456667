<template>
    <DefaultTemplate :model="model">
        <Breadcrumbs :breadcrumbs="model.breadcrumbs" />
        <div class="container lg:flex mb-10">
            <div class="w-full lg:w-3/4 lg:pr-2">
                <div>
                    <h1 v-if="thread.name"
                        v-html="thread.name"
                        class="font-bold text-4xl text-textgray lg:text-textgray-light mb-10"
                    >
                    </h1>
                </div>
                <template v-for="(thread, index) in thread.children">
                    <ForumThreadPost
                        :key="thread.id"
                        :thread="thread"
                        :class="index % 2 ? 'bg-white' : 'bg-blue-100'"
                        class="divide-y"
                        :buttonText="`Svar på dette innlegget`"
                        :buttonIcon="'comment'"
                        :model="model"
                    />
                </template>
            </div>

            <div class="w-full mt-10 lg:mt-20 lg:w-1/4 lg:pl-2">
                <template v-if="thread.documents">
                    <h3 class="font-bold text-xl text-textgray lg:text-textgray-light mb-2 border-b">
                        Dokumenter
                    </h3>
                    <ul class="mb-10">
                        <li v-for="file in thread.documents" :key="file.ID">
                            <a
                                :href="file.url"
                                :title="`Last ned ${file.name}`"
                                target="_blank"
                                class="underline hover:no-underline"
                            >
                                {{ file.name }}
                            </a>
                        </li>
                    </ul>
                </template>
                <div>
                    <UploadFile :id="model.current.id" />
                </div>
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import Breadcrumbs from '@/Scripts/components/organisms/Breadcrumbs/breadcrumbs.vue';
import ForumThreadPost from '@/Scripts/components/pages/forum/ForumThreadPost.vue';
import UploadFile from '@/Scripts/components/pages/forum/components/uploadfile.vue';

export default {
    props: ['model'],
    components: {
        Breadcrumbs,
        ForumThreadPost,
        UploadFile
    },
    data() {
        return {
            files: [],
            thread: this.model.current
        };
    },
    methods: {
        setState(obj) {
            Object.assign(this, obj);
        }
    }
};
</script>
