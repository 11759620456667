var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative mb-10" }, [
    _c("h2", {
      staticClass:
        "uppercase text-xl font-semibold tracking-wide text-gray-600 bg-white z-20 relative inline-block ml-10 px-6",
      domProps: { textContent: _vm._s(_vm.text) }
    }),
    _vm._v(" "),
    _c("div", {
      staticClass: "bg-gray-200 h-1 w-full absolute top-3 left-0 z-10"
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }