var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "li",
    { staticClass: "mb-4" },
    [
      _vm.item.children.length === 0
        ? [
            _c(
              "a",
              {
                staticClass:
                  "text-purple-600 fomt-bold no-underline hover:underline",
                attrs: { href: _vm.item.url, title: _vm.item.text }
              },
              [
                _c(
                  "span",
                  { staticClass: "text-sm text-black mr-1" },
                  [_c("font-awesome-icon", { attrs: { icon: "arrow-right" } })],
                  1
                ),
                _vm._v("\n            " + _vm._s(_vm.item.text) + "\n        ")
              ]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.item.children.length > 0
        ? [
            _c(
              "a",
              {
                staticClass:
                  "text-lg lg:text-base text-gray-800 font-bold no-underline hover:underline",
                attrs: { href: "#", title: _vm.item.text },
                on: {
                  click: function($event) {
                    $event.preventDefault()
                    _vm.showChildren = !_vm.showChildren
                  }
                }
              },
              [
                _c(
                  "span",
                  { staticClass: "text-sm text-black mr-1" },
                  [
                    !_vm.showChildren
                      ? _c("font-awesome-icon", {
                          attrs: { icon: "chevron-down" }
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.showChildren
                      ? _c("font-awesome-icon", {
                          attrs: { icon: "chevron-up" }
                        })
                      : _vm._e()
                  ],
                  1
                ),
                _vm._v("\n            " + _vm._s(_vm.item.text) + "\n        ")
              ]
            ),
            _vm._v(" "),
            _c(
              "ul",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showChildren,
                    expression: "showChildren"
                  }
                ],
                staticClass: "pl-4 list-outside mt-4"
              },
              [
                _vm._l(_vm.item.children, function(item, index) {
                  return [
                    _c("Item", { key: item.url + index, attrs: { item: item } })
                  ]
                })
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }