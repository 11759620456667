<template>
    <div class="md:flex py-2 divide-x">
        <div class="w-full md:w-2/4 px-2">
            <font-awesome-icon icon="comment-dots" class="mr-2"/>
            <a
                :href="thread.url"
                :title="thread.name"
                class="underline hover:text-blue-500"
            >
                {{ thread.name }}
            </a><br />
            <span class="text-xs">
                Av: {{ thread.createdBy }}
            </span>
        </div>

        <div class="hidden md:block w-1/4 px-2">
            {{ dateFormated }}
        </div>

        <div class="hidden md:block w-1/4 px-2 text-right">
            {{ thread.numberOfReplies }}
        </div>
    </div>
</template>

<script>
export default {
    props: ['thread'],
    computed: {
        dateFormated() {
            const d = new Date(this.thread.latestUpdate);
            const day = ('0' + d.getDate()).slice(-2);
            const month = ('0' + (d.getMonth() + 1)).slice(-2);
            const year = d.getFullYear();
            return day + '.' + month + '.' + year;
        }
    }
};
</script>
