<template>
    <ul>
        <template v-for="(item, index) in menu">
            <ItemHighlighted v-if="item.highlighted" :item="item" :key="item.url + index" />
            <Item v-else :item="item" :key="item.url + index" />
        </template>
    </ul>
</template>
<script>
import Item from '@/Scripts/components/organisms/Header/menuitem.vue';
import ItemHighlighted from '@/Scripts/components/organisms/Header/menuitemhighlighted.vue';
export default {
    props: ['menu'],
    components: {
        Item,
        ItemHighlighted
    }
};
</script>

<style scoped>
    ul > li.highlight:last-child {
        @apply -mb-4;
    }
</style>
