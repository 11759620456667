var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultTemplate",
    { attrs: { model: _vm.model } },
    [
      _c("Breadcrumbs", { attrs: { breadcrumbs: _vm.model.breadcrumbs } }),
      _vm._v(" "),
      _c("div", { staticClass: "container lg:flex mb-10" }, [
        _c(
          "div",
          { staticClass: "w-full lg:w-3/4 lg:pr-2" },
          [
            _c("div", [
              _vm.thread.name
                ? _c("h1", {
                    staticClass:
                      "font-bold text-4xl text-textgray lg:text-textgray-light mb-10",
                    domProps: { innerHTML: _vm._s(_vm.thread.name) }
                  })
                : _vm._e()
            ]),
            _vm._v(" "),
            _vm._l(_vm.thread.children, function(thread, index) {
              return [
                _c("ForumThreadPost", {
                  key: thread.id,
                  staticClass: "divide-y",
                  class: index % 2 ? "bg-white" : "bg-blue-100",
                  attrs: {
                    thread: thread,
                    buttonText: "Svar på dette innlegget",
                    buttonIcon: "comment",
                    model: _vm.model
                  }
                })
              ]
            })
          ],
          2
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "w-full mt-10 lg:mt-20 lg:w-1/4 lg:pl-2" },
          [
            _vm.thread.documents
              ? [
                  _c(
                    "h3",
                    {
                      staticClass:
                        "font-bold text-xl text-textgray lg:text-textgray-light mb-2 border-b"
                    },
                    [
                      _vm._v(
                        "\n                    Dokumenter\n                "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "ul",
                    { staticClass: "mb-10" },
                    _vm._l(_vm.thread.documents, function(file) {
                      return _c("li", { key: file.ID }, [
                        _c(
                          "a",
                          {
                            staticClass: "underline hover:no-underline",
                            attrs: {
                              href: file.url,
                              title: "Last ned " + file.name,
                              target: "_blank"
                            }
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(file.name) +
                                "\n                        "
                            )
                          ]
                        )
                      ])
                    }),
                    0
                  )
                ]
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              [_c("UploadFile", { attrs: { id: _vm.model.current.id } })],
              1
            )
          ],
          2
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }