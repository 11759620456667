var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    {
      staticClass:
        "container breadcrumbs flex flex-wrap mb-10 lg:mt-10 text-sm",
      class: [_vm.className]
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm._l(_vm.items, function(item, index) {
        return _c("li", { key: _vm.$uuid.v4(index), staticClass: "mr-2" }, [
          item.url
            ? _c(
                "a",
                { staticClass: "hover:underline", attrs: { href: item.url } },
                [
                  index === 0
                    ? _c("span", [_vm._v("Forside")])
                    : _c("span", [_vm._v(_vm._s(item.text))])
                ]
              )
            : _c("span", [
                _vm._v("\n            " + _vm._s(item.text) + "\n        ")
              ]),
          _vm._v(" "),
          index !== _vm.items.length - 1
            ? _c("span", { staticClass: "px-2" }, [_vm._v("→")])
            : _vm._e()
        ])
      })
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", { staticClass: "mr-2" }, [
      _vm._v("\n        Du er her\n        "),
      _c("span", { staticClass: "px-2" }, [_vm._v("→")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }