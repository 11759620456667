var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "py-4 my-4" },
    _vm._l(_vm.items, function(item) {
      return _c("li", { key: item.id, staticClass: "mb-6" }, [
        _c(
          "a",
          {
            staticClass:
              "text-blue-500 hover:text-red-500 underline hover:no-underline mb-1",
            attrs: { href: item.url, title: item.name }
          },
          [_vm._v("\n            " + _vm._s(item.name) + "\n        ")]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "text-sm text-gray-600 mb-1" }, [
          item.startPublish
            ? _c("span", {
                domProps: {
                  textContent: _vm._s(_vm.dateFormated(item.startPublish))
                }
              })
            : _vm._e(),
          _vm._v(" "),
          item.author
            ? _c("span", {
                domProps: { innerHTML: _vm._s(" &mdash; " + item.author) }
              })
            : _vm._e()
        ]),
        _vm._v(" "),
        item.introAsText
          ? _c("div", {
              staticClass: "text-sm",
              domProps: { textContent: _vm._s(item.introAsText) }
            })
          : _vm._e()
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }