var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DefaultTemplate",
    { attrs: { model: _vm.model } },
    [
      _c("Breadcrumbs", { attrs: { breadcrumbs: _vm.model.breadcrumbs } }),
      _vm._v(" "),
      _c("div", { staticClass: "container lg:flex mb-10" }, [
        _c(
          "div",
          {
            staticClass: "w-full",
            class: _vm.rightcol ? "lg:w-4/6" : "lg:max-w-6xl mx-auto"
          },
          [
            _c(
              "article-body",
              { attrs: { model: _vm.model } },
              [
                _vm.model.secondaryListItems
                  ? _c(
                      "section",
                      [
                        _c("secondary-list", {
                          attrs: { items: _vm.model.secondaryListItems }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.questions
                  ? _c(
                      "section",
                      [
                        _c("question-list", {
                          attrs: { items: _vm.model.questions }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.topics && _vm.model.showBoxes
                  ? _c(
                      "section",
                      [
                        _c("ExploreThemes", {
                          attrs: { themes: _vm.model.topics, headerline: false }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.children &&
                _vm.model.contentType[1] === "KommunetorgetTopicPage" &&
                !_vm.model.showBoxes
                  ? _c("NavChildren", {
                      attrs: { childrens: _vm.model.children }
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.model.video
                  ? _c("section", {
                      staticClass: "article--video",
                      domProps: { innerHTML: _vm._s(_vm.model.video) }
                    })
                  : _vm._e()
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "my-4 py-4 border-t border-gray-300 text-xs" },
              [_c("Sharing", { attrs: { title: _vm.model.heading } })],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _vm.rightcol || _vm.inEditMode
          ? _c("div", { staticClass: "w-full mt-6 lg:mt-0 lg:w-2/6" }, [
              _c(
                "div",
                { staticClass: "lg:p-6 lg:bg-white lg:bg-opacity-50" },
                [
                  _vm.model.rightImage
                    ? _c("img", {
                        directives: [
                          {
                            name: "epi-edit",
                            rawName: "v-epi-edit",
                            value: "RightImage",
                            expression: "'RightImage'"
                          }
                        ],
                        staticClass: "mb-4",
                        attrs: { src: _vm.model.rightImage }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.rightImage2
                    ? _c("img", {
                        staticClass: "mb-4",
                        attrs: { src: _vm.model.rightImage2 }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.rightImageText
                    ? _c("p", { staticClass: "mb-4" }, [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm.model.rightImageText) +
                            "\n                "
                        )
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.model.rightArea
                    ? _c("div", {
                        domProps: { innerHTML: _vm._s(_vm.model.rightArea) }
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  (_vm.model.relatedDocuments &&
                    _vm.model.relatedDocuments.length > 0) ||
                  _vm.inEditMode
                    ? [
                        _c("h3", { staticClass: "font-bold text-lg mb-1" }, [
                          _vm._v("Relaterte dokumenter")
                        ]),
                        _vm._v(" "),
                        _c(
                          "ul",
                          {
                            directives: [
                              {
                                name: "epi-edit",
                                rawName: "v-epi-edit",
                                value: "RelatedDocuments",
                                expression: "'RelatedDocuments'"
                              }
                            ],
                            staticClass: "mb-4"
                          },
                          _vm._l(_vm.model.relatedDocuments, function(doc) {
                            return _c("li", { key: doc.url }, [
                              _vm._v(
                                "\n                            → \n                            "
                              ),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "underline hover:no-underline text-blue-400 hover:text-red-400",
                                  attrs: {
                                    href: doc.url,
                                    title: doc.text,
                                    target: doc.target
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(doc.text) +
                                      "\n                            "
                                  )
                                ]
                              )
                            ])
                          }),
                          0
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ])
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }