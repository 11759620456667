var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DefaultTemplate", { attrs: { model: _vm.model } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.inEditMode,
            expression: "inEditMode"
          }
        ],
        staticClass: "container"
      },
      [
        _c("EpiProperty", {
          attrs: { propertyName: "Footer", displayText: "Footer" }
        }),
        _vm._v(" "),
        _c("EpiProperty", {
          attrs: {
            propertyName: "HighlightedTopicReference",
            displayText: "Fremhevet tema"
          }
        })
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container" },
      [
        _vm.model.highlightedTopic
          ? _c("HighlightedTopic", {
              attrs: { topic: _vm.model.highlightedTopic }
            })
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("ContentArea", {
              directives: [
                {
                  name: "epi-edit",
                  rawName: "v-epi-edit",
                  value: "MainContent",
                  expression: "'MainContent'"
                }
              ],
              attrs: { model: _vm.model.mainContent }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.inEditMode,
                expression: "inEditMode"
              }
            ]
          },
          [
            _c("ContentArea", {
              directives: [
                {
                  name: "epi-edit",
                  rawName: "v-epi-edit",
                  value: "TopicsContent",
                  expression: "'TopicsContent'"
                }
              ],
              attrs: { model: _vm.model.TopicsContent }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("ExploreThemes", {
          attrs: { themes: _vm.model.topics, headerline: true }
        }),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("ContentArea", {
              directives: [
                {
                  name: "epi-edit",
                  rawName: "v-epi-edit",
                  value: "SecondaryContent",
                  expression: "'SecondaryContent'"
                }
              ],
              attrs: { model: _vm.model.secondaryContent }
            })
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }