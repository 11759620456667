<!--
    Renders a ContentArea by iterating through all the blocks and using the
    `BlockComponentSelector` to render the corresponding Vue component.

    By setting the `data-epi-block-id` attribute the block becomes editable
    during On-Page Edit. It will not be set in View mode to not leak out too
    much info about Episerver to visitors.

    The model property is provided by the page or block that owns the
    ContentArea.
-->

<template>
    <section class="ContentArea">
        <div :key="index" v-for="(block, index) in model">
            <BlockComponentSelector :data-epi-block-id="isEditable ? block.contentLink.id : null" :model="block" className="o-section"></BlockComponentSelector>
            <span v-if="displaySeperator && index + 1 < model.length" class="u-seperator o-section"></span>
        </div>
    </section>
</template>

<script>
import BlockComponentSelector from '@/Scripts/components/BlockComponentSelector.vue';
import { mapState } from 'vuex';

export default {
    props: ['model', 'displaySeperator'],
    computed: mapState({
        isEditable: state => state.epiContext.isEditable
    }),
    components: {
        BlockComponentSelector
    }
};
</script>
