<template>
    <StandardPage :model="model"/>
</template>

<script>
import StandardPage from '@/Scripts/components/pages/KommunetorgetStandardPage.vue';

export default {
    props: ['model'],
    components: {
        StandardPage
    }
};
</script>
