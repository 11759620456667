<template>
    <article class="article">
        <h1 v-epi-edit="'Heading'" v-if="model.heading || inEditMode" v-text="model.heading" class="font-bold text-5xl text-textgray lg:text-textgray-light mb-10"></h1>

        <div v-epi-edit="'MainIntro'" v-if="model.mainIntro || inEditMode" v-html="model.mainIntro" class="article--ingress font-bold leading-7 mb-4"></div>

        <Slider v-epi-edit="'Images'" v-if="model.images.length > 0 || inEditMode" :images="model.images" />
        <div v-if="inEditMode">
             <EpiProperty propertyName="RightImage" displayText="Hovedbilde" />
             <EpiProperty propertyName="RightImageText" displayText="Bildetekst" />
        </div>

        <div  v-epi-edit="'MainBody'" v-if="model.mainBody || inEditMode" v-html="model.mainBody" class="article--mainbody"></div>

        <ContentArea v-epi-edit="'MainContentArea'" :model="model.mainContentArea" />

        <div v-epi-edit="'SecondaryBody'" v-if="model.secondaryBody || inEditMode" v-html="model.secondaryBody" class="article--mainbody"></div>

        <slot></slot>
    </article>
</template>

<script>
import Slider from '@/Scripts/components/atoms/Slider/Slider.vue';
import ContentArea from '@/Scripts/components/ContentArea.vue';
import { mapGetters } from 'vuex';
import EpiProperty from '@/Scripts/components/EpiProperty.vue';

export default {
    props: ['model'],
    components: {
        Slider,
        ContentArea,
        EpiProperty
    },
    computed: {
        ...mapGetters({
            inEditMode: 'getInEditMode'
        })
    },
};
</script>
