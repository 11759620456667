var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "p",
      {
        staticClass:
          "py-1 px-2 bg-blue-100 text-blue-500 text-xs inline-block mb-1"
      },
      [_vm._v("\n        " + _vm._s(_vm.item.site) + "\n    ")]
    ),
    _vm._v(" "),
    _c(
      "h3",
      { staticClass: "md:flex md:justify-between text-lg mb-2 md:items-start" },
      [
        _c(
          "a",
          {
            staticClass: "text-blue-500 underline hover:text-red-500",
            attrs: { href: _vm.item.url, title: _vm.item.title }
          },
          [_vm._v("\n            " + _vm._s(_vm.item.title) + "\n        ")]
        )
      ]
    ),
    _vm._v(" "),
    _c("div", [
      _vm.item.publishDate
        ? _c("span", {
            staticClass: "font-semibold",
            domProps: { textContent: _vm._s(_vm.dateFormated) }
          })
        : _vm._e(),
      _vm._v(" "),
      _c("span", { domProps: { innerHTML: _vm._s(_vm.item.extract) } })
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }