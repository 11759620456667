<template>
    <DefaultTemplate :model="model">
        <div class="container">
            <div class="block lg:flex">
                <div class="w-full lg:w-3/4 pr-0 lg:pr-4">
                    <h1 v-epi-edit="'Name'" class="mb-2">
                        {{ model.name }}
                    </h1>
                    <hr class="my-4 lg:my-10" />
                    {{log(model)}}
                    <ContentArea v-epi-edit="'LeftContentArea'" :model="model.leftContentArea" />
                </div>
                <aside class="w-full lg:w-1/4 pr-0 lg:pl-4 mb-4">
                    <div class="p-5 mb-6">
                        <ContentArea v-epi-edit="'RightContentArea'" :model="model.rightContentArea" />
                    </div>
                </aside>
            </div>
        </div>
    </DefaultTemplate>
</template>

<script>
import ContentArea from '@/Scripts/components/ContentArea.vue';

export default {
    props: ['model'],
    components: {
        ContentArea,
    },
    methods: {
        log(obj) {
            console.log(obj);
        }
    }
};
</script>
