var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "bg-gray-100" }, [
    _vm.content.content
      ? _c("div", { staticClass: "border-2 article--mainbody" }, [
          _vm.content.heading
            ? _c("div", { staticClass: "text-xl p-4 font-semibold" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.content.heading) + "\n        "
                )
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", {
            staticClass: "p-4",
            domProps: { innerHTML: _vm._s(_vm.content.content) }
          })
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }