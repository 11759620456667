var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DefaultTemplate", { attrs: { model: _vm.model } }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "block lg:flex" }, [
        _c(
          "div",
          { staticClass: "w-full lg:w-3/4 pr-0 lg:pr-4" },
          [
            _c(
              "h1",
              {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "Name",
                    expression: "'Name'"
                  }
                ],
                staticClass: "mb-2"
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.model.name) +
                    "\n                "
                )
              ]
            ),
            _vm._v(" "),
            _c("hr", { staticClass: "my-4 lg:my-10" }),
            _vm._v(
              "\n                " +
                _vm._s(_vm.log(_vm.model)) +
                "\n                "
            ),
            _c("ContentArea", {
              directives: [
                {
                  name: "epi-edit",
                  rawName: "v-epi-edit",
                  value: "LeftContentArea",
                  expression: "'LeftContentArea'"
                }
              ],
              attrs: { model: _vm.model.leftContentArea }
            })
          ],
          1
        ),
        _vm._v(" "),
        _c("aside", { staticClass: "w-full lg:w-1/4 pr-0 lg:pl-4 mb-4" }, [
          _c(
            "div",
            { staticClass: "p-5 mb-6" },
            [
              _c("ContentArea", {
                directives: [
                  {
                    name: "epi-edit",
                    rawName: "v-epi-edit",
                    value: "RightContentArea",
                    expression: "'RightContentArea'"
                  }
                ],
                attrs: { model: _vm.model.rightContentArea }
              })
            ],
            1
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }