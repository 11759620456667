var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "relative w-full md:w-1/3 mb-4 hover:shadow-lg" },
    [
      _c("div", { staticClass: "w-full relative imageplaceholder" }, [
        _c("img", {
          staticClass: "absolute top-0 left-0",
          attrs: { src: _vm.imageUrl, alt: _vm.article.heading }
        })
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "p-4" }, [
        _c("h3", {
          staticClass: "font-bold text-xl text-textgray mb-2",
          domProps: { textContent: _vm._s(_vm.article.heading) }
        }),
        _vm._v(" "),
        _c("p", { domProps: { innerHTML: _vm._s(_vm.article.intro) } })
      ]),
      _vm._v(" "),
      _c(
        "a",
        {
          staticClass: "absolute w-full h-full top-0 left-0",
          attrs: { href: _vm.article.url, title: _vm.article.heading }
        },
        [_c("span", { staticClass: "sr-only" }, [_vm._v("Les mer")])]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }