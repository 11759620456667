<template>
    <form @submit.prevent="createForum()" class="flex flex-col space-y-4 w-full lg:max-w-5xl lg:mx-auto">
        <template v-if="loading">
            <LoadingSpinner size="2x" />
        </template>
        <template v-if="!error && !loading">
            <input placeholder="Tittel" v-model="forumName" class="border p-3 rounded bg-white" />
            <div class="flex space-x-4">
                <label for="allowThreads">
                    <input id="allowThreads" type="checkbox" v-model="allowThreads" />
                    Tillat innlegg
                </label>
                <label for="allowForums">
                    <input id="allowForums" type="checkbox" v-model="allowForums" />
                    Tillat forum
                </label>
            </div>
            <div class="text-right">
                <button type="submit" class="btn btn-blue">Opprett forum</button>
                <button type="button" class="btn btn-red" @click.prevent="close()">Avbryt</button>
            </div>
        </template>
        <template v-if="error">
            <div class="text-center bg-red-100 border border-red-200 text-red-600 p-4">
                Beklager, det oppso en feil! Prøv igjen. Hvis problemet fortsetter vennligst ta kontakt med oss.
            </div>
        </template>
    </form>
</template>

<script>
import api from '@/Scripts/api/api';
import LoadingSpinner from '@/Scripts/components/atoms/LoadingSpinner/loadingSpinner.vue';

export default {
    props: ['model'],
    components: {
        LoadingSpinner
    },
    data() {
        return {
            forumName: '',
            allowThreads: false,
            allowForums: false,
            error: false,
            loading: false
        };
    },
    methods: {
        createForum() {
            const self = this;
            self.loading = true;
            const body = {
                parentId: self.model.contentLink.id,
                forumName: self.forumName,
                allowThreads: self.allowThreads,
                allowForums: self.allowForums
            };
            api.createForum(body).then(response => {
                self.loading = false;
                if (response.status === 200) {
                    self.forumName = '';
                    self.allowThreads = false;
                    self.allowForums = false;
                    if (response.data.Data.ForumPageUrl) {
                        window.location = response.data.Data.ForumPageUrl;
                    } else {
                        location.reload();
                    }
                } else {
                    self.error = true;
                }
            });
        },
        close() {
            this.$emit('clicked', 'someValue');
        }
    },
};
</script>
